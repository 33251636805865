import React, { useState } from "react";
import { Modal, Button, List } from "semantic-ui-react";
import { useAppDispatch } from "app/store";
import { Pack } from "api/types";
import PackForm from "./PackForm";
import { deletePack } from "./packSlice";

interface PackListItemProps {
  pack: Pack;
}

const PackListItem: React.FC<PackListItemProps> = ({ pack }) => {
  const dispatch = useAppDispatch();
  const [isConfirmOpen, setIsConfirmOpen] = useState<boolean>(false);

  const openConfirm = () => {
    setIsConfirmOpen(true);
  };
  const closeConfirm = () => {
    setIsConfirmOpen(false);
  };
  const onDeleteConfirm = () => {
    const result = dispatch(deletePack(pack.id));
    console.log("deletePack result: ", result);
  };

  const renderDeleteConfirm = () => {
    const trigger = (
      <span
        style={{ color: "red", fontSize: "0.9rem", cursor: "pointer" }}
        onClick={openConfirm}
      >
        Delete
      </span>
    );
    return (
      <Modal
        size="mini"
        open={isConfirmOpen}
        onClose={closeConfirm}
        trigger={trigger}
      >
        <Modal.Header>Are you sure to delete?</Modal.Header>
        <Modal.Content>
          Pack to be deleted: <strong>{pack.name}</strong>
        </Modal.Content>
        <Modal.Actions>
          <Button negative content="Cancel" onClick={closeConfirm} />
          <Button positive content="Confirm" onClick={onDeleteConfirm} />
        </Modal.Actions>
      </Modal>
    );
  };

  return (
    <List.Item
      className="pack-item"
      style={{
        width: "160px",
        margin: "1rem 0rem",
        padding: "0px 5px",
        verticalAlign: "top",
      }}
    >
      <div className="pack-name">
        <strong>{pack.name}</strong>
      </div>
      <div className="pack-breakdown">{pack.breakdown}</div>
      <div className="pack-size">{pack.size}</div>
      <div>
        <PackForm pack={pack} />
        {" | "}
        {renderDeleteConfirm()}
      </div>
    </List.Item>
  );
};

export default PackListItem;
