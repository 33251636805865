import React from "react";

interface HomeProps {}

const Home: React.FC<HomeProps> = () => {
  return (
    <div>
      <h1>Home page</h1> <p>welcome!</p>
    </div>
  );
};

export default Home;
