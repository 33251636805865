import React from "react";
import { DropdownProps, Form } from "semantic-ui-react";

interface ColorDropdownProps {
  colors: string[];
  label?: string;
  name: string;
  value: string | "";
  compact?: boolean;
  simple?: boolean;
  onChange: (
    e: React.SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps
  ) => void;
}

const ColorDropdown: React.FC<ColorDropdownProps> = ({
  colors,
  label,
  name,
  value,
  compact = false,
  simple = false,
  onChange,
}) => {
  const colorOptions = () => {
    const options = Object.values(colors).map((c: string) => {
      return { key: c, text: c, value: c };
    });
    return [{ key: "NULL", text: "No Color", value: "NULL" }, ...options];
  };

  return (
    <Form.Dropdown
      label={label}
      name={name}
      options={colorOptions()}
      value={value ? value : "NULL"}
      onChange={onChange}
      selection
      compact={compact}
      simple={simple}
      placeholder="SelectColor"
    />
  );
};

export default ColorDropdown;
