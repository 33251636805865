import React, { useState, useRef } from "react";
import { Input, Button } from "semantic-ui-react";
import styled from "styled-components";
import BarcodeScanner from "features/barcode/BarcodeScanner";
import { QuaggaJSResultObject } from "@ericblade/quagga2";

const ScannerView = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
  video,
  canvas {
    width: 100%;
    max-width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  button {
    display: inline-block;
    position: absolute;
    z-index: 20;
    right: 10px;
    background: black;
    color: red;
    border: none;
    font-size: x-large;
  }
`;

interface OrderFormSearchSectionProps {}

const OrderFormSearchSection: React.FC<OrderFormSearchSectionProps> = ({}) => {
  const [scanning, setScanning] = useState(false);
  const [results, setResults] = useState<QuaggaJSResultObject[]>([]);
  const scannerRef = useRef(null);

  const handleResult = (result: QuaggaJSResultObject) => {
    setScanning(false);
    console.log("scanned: ", result.codeResult.code);
    setResults([...results, result]);
  };

  return (
    <>
      <ScannerView ref={scannerRef}>
        <canvas className="drawingBuffer" />
        <Button icon="close" />
        {scanning ? (
          <BarcodeScanner scannerRef={scannerRef} onDetected={handleResult} />
        ) : null}
      </ScannerView>
      <Input action>
        <input placeholder="Scan or type barcode" />
        <Button
          icon="camera"
          color="twitter"
          onClick={() => setScanning(true)}
        />
        <Button icon="barcode" color="blue" />
      </Input>
      <ul>
        {results.map(
          (result) =>
            result.codeResult && (
              <li key={result.codeResult.code}>result.codeResult.code</li>
            )
        )}
      </ul>
    </>
  );
};

export default OrderFormSearchSection;
