import React from "react";
import { List } from "semantic-ui-react";
import { Color } from "api/types";
import ColorListItem from "./ColorListItem";

interface ColorListProps {
  colors: Color[];
}

const ColorList: React.FC<ColorListProps> = ({ colors }) => {
  const colorItems = colors.map((color) => (
    <ColorListItem key={color.id} color={color} />
  ));
  return <List horizontal>{colorItems}</List>;
};

export default ColorList;
