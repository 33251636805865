import _ from "lodash";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { useHistory } from "react-router-dom";
import { Listing, Size, Product } from "api/types";
import { ListingFormData, ListingVariationFormData } from "api/listingAPI";
import { RootState } from "app/rootReducer";
import { useAppDispatch } from "app/store";
import { fetchProductById, resetProducts } from "features/product/productSlice";
import {
  InputProps,
  DropdownProps,
  CheckboxProps,
  Segment,
  Form,
  Confirm,
  Button,
} from "semantic-ui-react";
import {
  removeListingVariations,
  updateListing,
  createListing,
  deleteListing,
} from "../listingSlice";
import ReferenceSection from "./ReferenceSection";
import ListingFormInfoSection from "./ListingFormInfoSection";
import ListingFormVariationsSection from "./ListingFormVariationsSection";
import ListingFormImageSection from "./ListingFormImageSection";

interface ListingFormProps {
  listing: Listing | null;
  loading: boolean;
}

const initialData: ListingFormData = {
  product: null,
  styleNumber: "",
  name: "",
  description: "",
  category: null,
  isMain: false,
  isActive: false,
  isInstock: false,
  isOnesize: false,
  regularPrice: 0,
  plusPrice: 0,
  onesizePrice: 0,
  regularPack: null,
  plusPack: null,
  onesizePack: null,
  images: [],
  variations: [],
};

const ListingForm: React.FC<ListingFormProps> = ({ listing, loading }) => {
  const { entities: productsById } = useSelector(
    (state: RootState) => state.products
  );
  const { entities: packsById } = useSelector(
    (state: RootState) => state.packs
  );
  const { entities: categoriesById } = useSelector(
    (state: RootState) => state.categories
  );
  const [listingData, setListingData] = useState<ListingFormData>(initialData);
  const [referenceProduct, setReferenceProduct] = useState<Product | null>(
    null
  );
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const history = useHistory();

  const handleChange = (fieldName: string) => (
    _: React.SyntheticEvent<HTMLElement, Event>,
    data: InputProps | DropdownProps | CheckboxProps
  ) => {
    switch (fieldName) {
      case "isOnesize":
      case "isMain":
      case "isInstock":
        setListingData({ ...listingData, [fieldName]: data.checked });
        break;
      case "regularPrice":
      case "plusPrice":
      case "onesizePrice":
        handlePriceChange(fieldName, data.value);
        break;
      case "regularPack":
      case "plusPack":
      case "onesizePack":
        handlePackChange(fieldName, data.value);
        break;
      case "category":
        handleCategoryChange(fieldName, data.value);
        break;
      case "styleNumber":
        setListingData({
          ...listingData,
          [fieldName]: data.value.toUpperCase(),
        });
        break;
      default:
        setListingData({ ...listingData, [fieldName]: data.value });
    }
  };

  const handlePackChange = (fieldName: string, id: number) => {
    const pack = id === 0 ? null : packsById[id];
    setListingData({ ...listingData, [fieldName]: pack });
  };

  const handleCategoryChange = (fieldName: string, id: number) => {
    const category = categoriesById[id];
    setListingData({ ...listingData, [fieldName]: category });
  };

  const handlePriceChange = (fieldName: string, value: string) => {
    const re = /^\d+(\.\d{0,2})?$/;
    if (value === "" || re.test(value)) {
      setListingData({
        ...listingData,
        [fieldName]: value,
      });
    }
  };

  const handleVariationQtyChange = (color: string, size: Size, qty: number) => {
    let newVariations = listingData.variations.filter(
      (v) => !(v.size === size && v.color === color)
    );
    const target = _.find(listingData.variations, { color, size });
    if (target) {
      newVariations.push({ ...target, qty });
    }
    newVariations = _.orderBy(
      newVariations,
      ["color", "size"],
      ["asc", "desc"]
    );
    setListingData({
      ...listingData,
      variations: newVariations,
    });
  };

  const handleVariationAdd = (variation: ListingVariationFormData) => {
    if (_.find(listingData.variations, variation)) {
      // TODO: may need to check when updating.
      return;
    }
    let newVariations = [...listingData.variations, variation];
    newVariations = _.orderBy(
      newVariations,
      ["color", "size"],
      ["asc", "desc"]
    );
    setListingData({
      ...listingData,
      variations: newVariations as ListingVariationFormData[],
    });
  };

  const handleVariationRemoval = (
    variationId: number | undefined,
    color: string,
    size: Size
  ) => {
    const newVariations = listingData.variations.filter(
      (v) => !(v.size === size && v.color === color)
    );
    setListingData({
      ...listingData,
      variations: newVariations,
    });
    if (listing && variationId) {
      dispatch(
        removeListingVariations({ id: listing.id, data: [variationId] })
      );
    }
  };

  const handleDelete = () => {
    if (listing) {
      dispatch(deleteListing(listing.id)).then(() => {
        history.push("/listings");
      });
    }
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (listing) {
      dispatch(updateListing({ ...listingData, id: listing.id }));
    } else {
      dispatch(createListing(listingData))
        .then(unwrapResult)
        .then((listing) => {
          history.push(`/listings/${listing.id}`);
        });
    }
  };

  const handleReferenceSelect = (productId: number, yes?: boolean) => {
    if (!productsById[productId] || yes) {
      console.log("getting");
      dispatch(fetchProductById(productId))
        .then(unwrapResult)
        .then((product) => {
          setReferenceProduct(product);
        });
    }
  };

  useEffect(() => {
    if (listing) {
      const {
        id,
        createdAt,
        updatedAt,
        regularPrice,
        plusPrice,
        onesizePrice,
        ...fields
      } = listing;
      setListingData({
        regularPrice: Number((regularPrice / 100).toFixed(2)),
        plusPrice: Number((plusPrice / 100).toFixed(2)),
        onesizePrice: Number((onesizePrice / 100).toFixed(2)),
        ...fields,
      });
      handleReferenceSelect(listing.product);
    } else {
      setListingData(initialData);
      setReferenceProduct(null);
      dispatch(resetProducts());
    }
    // eslint-disable-next-line
  }, [dispatch, listing]);

  useEffect(() => {
    if (referenceProduct && !listing) {
      const {
        id: productId,
        createdAt,
        updatedAt,
        regularPrice,
        plusPrice,
        onesizePrice,
        variations,
        ...fields
      } = referenceProduct;
      setListingData({
        ...initialData,
        product: productId,
        regularPrice: Number((regularPrice / 100).toFixed(2)),
        plusPrice: Number((plusPrice / 100).toFixed(2)),
        onesizePrice: Number((onesizePrice / 100).toFixed(2)),
        variations: variations.map((v) => {
          return {
            color: v.color,
            size: v.size,
            pack: v.pack,
            packBreakdown: v.packBreakdown,
            qty: 0,
          };
        }),
        ...fields,
      });
    }
  }, [referenceProduct, listing]);

  return (
    <>
      {!listing && (
        <Segment>
          <ReferenceSection onReferenceSelect={handleReferenceSelect} />
        </Segment>
      )}
      {(referenceProduct || listing) && (
        <Form onSubmit={(e) => e.preventDefault()} loading={loading}>
          <Segment.Group>
            <Segment>
              <ListingFormInfoSection
                item={listingData}
                onChange={handleChange}
                referenceProduct={referenceProduct}
              />
            </Segment>
            <Segment>
              <ListingFormVariationsSection
                item={listingData}
                onVariationQtyChange={handleVariationQtyChange}
                onVariationAdd={handleVariationAdd}
                onVariationRemoval={handleVariationRemoval}
              />
            </Segment>
            <Segment>
              <ListingFormImageSection item={listingData} />
            </Segment>
          </Segment.Group>
          <Form.Group>
            {listing && (
              <>
                <Form.Button
                  type="button"
                  content="Delete"
                  onClick={() => setConfirmOpen(true)}
                  color="red"
                />
                <Confirm
                  header="Are you sure?"
                  content="This action cannot be undone."
                  open={confirmOpen}
                  size="mini"
                  onCancel={() => setConfirmOpen(false)}
                  onConfirm={handleDelete}
                  confirmButton={<Button content="Delete" negative />}
                />
              </>
            )}
            <Form.Button
              type="button"
              content="Cancel"
              onClick={() => history.push("/listings")}
              color="orange"
            />
            <Form.Button
              type="submit"
              content="Submit"
              onClick={handleSubmit}
              loading={loading}
              primary
            />
          </Form.Group>
        </Form>
      )}
    </>
  );
};

export default ListingForm;
