import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import categoryAPI from "api/categoryAPI";
import { Category } from "api/types";
// import { AxiosResponse } from "axios";

export const fetchCategories = createAsyncThunk<Category[]>(
  "categories/fetch",
  async (_, { rejectWithValue }) => {
    try {
      const response = await categoryAPI.fetch();
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const createCategory = createAsyncThunk<Category, Omit<Category, "id">>(
  "categories/create",
  async (categoryData, { rejectWithValue }) => {
    try {
      const response = await categoryAPI.create(categoryData);
      console.log("in thunk", response);
      return response;
    } catch (error) {
      if (!error.response) {
        console.log("createCategory: throwing");
        throw error;
      }
      console.log("in slice", error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateCategory = createAsyncThunk<
  Category,
  { id: number } & Partial<Category>
>("categories/update", async (categoryData, { rejectWithValue }) => {
  try {
    const { id, ...fields } = categoryData;
    const response = await categoryAPI.updateById(id, fields);
    console.log(response);
    return response;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});

export const deleteCategory = createAsyncThunk(
  "categories/delete",
  async (id: number, { rejectWithValue }) => {
    try {
      await categoryAPI.deleteById(id);
      return id;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

const startLoading = (state: IState) => {
  state.loading = true;
  state.error = null;
};

const loadingFailed = (state: IState, action: PayloadAction<unknown>) => {
  console.log("in reducer", action);
  state.loading = false;
  state.error = action.payload;
};

interface IState {
  entities: Record<number, Category>;
  loading: boolean;
  error: string | null | undefined | any;
}

const initialState: IState = {
  entities: {},
  loading: false,
  error: null,
};

export const categorySlice = createSlice({
  name: "categories",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, startLoading)
      .addCase(fetchCategories.rejected, loadingFailed)
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        action.payload.map(
          (category: Category) => (state.entities[category.id] = category)
        );
        // state.entities = action.payload;
      });
    builder
      .addCase(createCategory.pending, startLoading)
      .addCase(createCategory.rejected, loadingFailed)
      .addCase(createCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        const { id } = action.payload;
        state.entities[id] = action.payload;
      });
    builder
      .addCase(updateCategory.pending, startLoading)
      .addCase(updateCategory.rejected, loadingFailed)
      .addCase(updateCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        const { id } = action.payload;
        state.entities[id] = action.payload;
      });
    builder
      .addCase(deleteCategory.pending, startLoading)
      .addCase(deleteCategory.rejected, loadingFailed)
      .addCase(deleteCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        const id = action.payload;
        delete state.entities[id];
      });
  },
});

export default categorySlice.reducer;
