import React, { useState, useEffect, useCallback } from "react";
import { useDropzone, DropzoneRootProps } from "react-dropzone";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Header, Card } from "semantic-ui-react";
import styled from "styled-components";
import { ProductFormValues } from "api/productAPI";
import ImageItem from "./ImageItem";

const getBorderColor = (props: DropzoneRootProps) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isDragActive) {
    return "#2196f3";
  }
  return "#eeeeee";
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getBorderColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  cursor: pointer;
  p {
    font-size: 2em;
  }
`;

interface ImagesFormSectionProps {
  item: ProductFormValues;
  onImageAddition: Function;
  onImageRemoval: Function;
  onImageColorChange: (position: number, color: string) => void;
  onImageReposition: (curr: number, target: number) => void;
}

const ImagesFormSection: React.FC<ImagesFormSectionProps> = ({
  item,
  onImageAddition,
  onImageRemoval,
  onImageColorChange,
  onImageReposition,
}) => {
  const [currentDragIndex, setCurrentDragIndex] = useState<number | null>(null);
  const [dropzoneProps, setDropzoneProps] = useState({});
  const [dropzonePlaceholder, setDropzonePlaceholder] = useState(
    "Click to select files."
  );
  const onDrop = useCallback(
    (files: File[]) => {
      console.log("onDrop called");
      onImageAddition(files);
    },
    [onImageAddition]
  );

  // let dropzoneProps;
  // if (item.images.length === 0) {
  //   dropzoneProps = { noDrag: true };
  // } else {
  //   dropzoneProps = { onDrop, multiple: true };
  // }
  useEffect(() => {
    if (item.images.length === 0) {
      setDropzoneProps({ onDrop, noDrag: true });
      setDropzonePlaceholder("Click to select files.");
    } else {
      setDropzoneProps({ onDrop, multiple: true });
      setDropzonePlaceholder(
        "Drag n drop image files, or click to select files."
      );
    }
  }, [item.images.length, onDrop]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    isDragAccept,
  } = useDropzone(dropzoneProps);

  const getColors = () => {
    if (item.variations)
      return item.variations
        .map((v) => v.color)
        .filter((value, index, self) => self.indexOf(value) === index);
    return [];
  };

  const renderImageItems = () => {
    return item.images.map((image, index) => (
      <ImageItem
        key={index}
        index={index}
        image={image}
        colors={getColors()}
        onDelete={onImageRemoval}
        onColorChange={onImageColorChange}
        onReposition={onImageReposition}
        setCurrentDragIndex={setCurrentDragIndex}
        currentDragIndex={currentDragIndex}
      />
    ));
  };

  return (
    <>
      <Header size="medium">Images</Header>
      <Container
        {...getRootProps({ isDragAccept, isDragActive, isDragReject })}
      >
        <input {...getInputProps()} />
        <p>{dropzonePlaceholder}</p>
      </Container>
      <DndProvider backend={HTML5Backend}>
        <Card.Group>{renderImageItems()}</Card.Group>
      </DndProvider>
    </>
  );
};

export default ImagesFormSection;
