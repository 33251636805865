import React, { useState } from "react";
import { Modal, Button, List } from "semantic-ui-react";
import { useAppDispatch } from "app/store";
import { Category } from "api/types";
import CategoryForm from "./CategoryForm";
import { deleteCategory } from "./categorySlice";

interface CategoryListItemProps {
  category: Category;
}

const CategoryListItem: React.FC<CategoryListItemProps> = ({ category }) => {
  const dispatch = useAppDispatch();
  const [isConfirmOpen, setIsConfirmOpen] = useState<boolean>(false);

  const openConfirm = () => {
    setIsConfirmOpen(true);
  };
  const closeConfirm = () => {
    setIsConfirmOpen(false);
  };
  const onDeleteConfirm = () => {
    const result = dispatch(deleteCategory(category.id));
    console.log("deleteCategory result: ", result);
  };

  const renderDeleteConfirm = () => {
    const trigger = (
      <span
        style={{ color: "red", fontSize: "0.9rem", cursor: "pointer" }}
        onClick={openConfirm}
      >
        Delete
      </span>
    );
    return (
      <Modal
        size="mini"
        open={isConfirmOpen}
        onClose={closeConfirm}
        trigger={trigger}
      >
        <Modal.Header>Are you sure to delete?</Modal.Header>
        <Modal.Content>
          Category to be deleted: <strong>{category.name}</strong>
        </Modal.Content>
        <Modal.Actions>
          <Button negative content="Cancel" onClick={closeConfirm} />
          <Button positive content="Confirm" onClick={onDeleteConfirm} />
        </Modal.Actions>
      </Modal>
    );
  };

  return (
    <List.Item
      className="category-item"
      style={{
        width: "160px",
        margin: "1rem 0rem",
        padding: "0px 5px",
        verticalAlign: "top",
      }}
    >
      <div className="category-name">
        <strong>{category.name}</strong>
      </div>
      <div>
        <CategoryForm category={category} />
        {" | "}
        {renderDeleteConfirm()}
      </div>
    </List.Item>
  );
};

export default CategoryListItem;
