import React from "react";
import { Listing, ListingVariation } from "api/types";
import styled from "styled-components";
import Barcode from "./Barcode";

interface BarcodePrintProps {
  listings: Listing[];
}

class BarcodePrint extends React.Component<BarcodePrintProps, {}> {
  data = (listing: Listing) => {
    const byColors: Record<string, Record<string, ListingVariation>> = {};
    listing.variations.map((v) => {
      const color = v.color;
      if (byColors[color] === undefined) byColors[color] = {};
      if (v.size === "REGULAR") {
        byColors[color].regular = v;
      } else if (v.size === "PLUS") {
        byColors[color].plus = v;
      } else {
        byColors[color].onesize = v;
      }
      return null;
    });
    return byColors;
  };

  renderImages = (listing: Listing) => {
    const idx = Math.min(listing.images.length, 3);
    const images = [];
    for (let i = 0; i < idx; i++) {
      images.push(
        <Image
          key={listing.images[i].id}
          src={listing.images[i].image as string}
          alt="Image"
        />
      );
    }
    return images;
  };

  renderPrice = (listing: Listing) => {
    if (listing.isOnesize) {
      return <Price>${(listing.onesizePrice / 100).toFixed(2)}</Price>;
    }
    return (
      <Price>
        ${(listing.regularPrice / 100).toFixed(2)} / $
        {(listing.plusPrice / 100).toFixed(2)}
      </Price>
    );
  };

  renderBarcode = (value: string) => {
    return <Barcode value={value} />;
  };

  renderItem = (listing: Listing, variation: ListingVariation) => {
    let price;
    if (variation.size === "REGULAR") price = listing.regularPrice;
    else if (variation.size === "PLUS") price = listing.plusPrice;
    else price = listing.onesizePrice;
    price = (price / 100).toFixed(2);
    const qrValue = `${pad(listing.id, 7)}-${pad(variation.id, 9)}`;
    return (
      <Item>
        <ItemBarcode>{this.renderBarcode(qrValue)}</ItemBarcode>
        <ItemInfo>
          <ItemColor>{variation.color}</ItemColor>
          <ItemSize>{variation.size}</ItemSize>
          <ItemPrice>${price}</ItemPrice>
        </ItemInfo>
      </Item>
    );
  };

  renderGroup = (
    listing: Listing,
    variations: Record<string, ListingVariation>,
    colorName: string
  ) => {
    return (
      <ItemGroup key={colorName}>
        {variations.regular && this.renderItem(listing, variations.regular)}
        {variations.plus && this.renderItem(listing, variations.plus)}
        {variations.onesize && this.renderItem(listing, variations.onesize)}
      </ItemGroup>
    );
  };

  renderList = (listing: Listing) => {
    const data = this.data(listing);
    return Object.entries(data).map((entry) => {
      return this.renderGroup(listing, entry[1], entry[0]);
    });
  };

  render() {
    return (
      <div id="qrcode-print">
        {this.props.listings.map((listing) => {
          return (
            <Page key={listing.id}>
              <Header>
                <span>
                  <Logo
                    src="https://heimishusa.s3-us-west-2.amazonaws.com/public/static/logo_heimishusa_md.png"
                    alt="Logo"
                  />
                </span>
                <Info>
                  <StyleNumber>
                    {listing.styleNumber}
                    {listing.isInstock ? " RTS" : ""}
                  </StyleNumber>
                  {this.renderPrice(listing)}
                </Info>
              </Header>
              <ImageSection>{this.renderImages(listing)}</ImageSection>
              <ItemList>{this.renderList(listing)}</ItemList>
            </Page>
          );
        })}
      </div>
    );
  }
}

const pad = (element: number, length: number, z: string = "0") => {
  const temp = "" + element;
  return temp.length >= length
    ? temp
    : new Array(length - temp.length + 1).join(z) + element;
};

export default BarcodePrint;

const Page = styled.div`
  page-break-inside: avoid;
  page-break-after: always;
  vertical-align: middle;
  width: 1100px;
  padding: 50px 40px;
`;

const Header = styled.div`
  padding-bottom: 40px;
`;
const Logo = styled.img`
  display: inline-block;
  width: 200px;
`;

const Info = styled.div`
  display: inline-block;
  float: right;
  text-align: right;
  height: 100px;
`;

const StyleNumber = styled.div`
  height: 45px;
  margin-top: 8px;
  font-size: 35pt;
`;

const Price = styled.div`
  height: 40px;
  font-size: 18pt;
`;

const ImageSection = styled.div`
  vertical-align: top;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
`;

const Image = styled.img`
  width: 250px;
  display: inline-block;
  margin: 0px 10px;
`;

const ItemList = styled.div``;

const ItemGroup = styled.div`
  width: 25%;
  display: inline-block;
  // padding-top: 15px;
  margin-top: 15px;
  margin-bottom: 10px;
  vertical-align: top;
  text-align: center;
`;

const Item = styled.div`
  width: 200px;
  margin-left: auto;
  margin-right: auto;
`;

const ItemBarcode = styled.div`
  vertical-align: top;
`;

const ItemInfo = styled.div`
  margin-top: -10px;
`;

const ItemColor = styled.div`
  font-size: 13pt;
  font-weight: 500;
  margin-bottom: 1px;
`;

const ItemSize = styled.div`
  font-size: 10pt;
`;

const ItemPrice = styled.div`
  font-size: 10pt;
`;
