import React from "react";
import { useSelector } from "react-redux";
import { DropdownProps, Form } from "semantic-ui-react";
import { RootState } from "app/rootReducer";
import { Category } from "api/types";

interface CategoryDropdownProps {
  name: string;
  value: number | undefined;
  onChange: (
    e: React.SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps
  ) => void;
}

const CategoryDropdown: React.FC<CategoryDropdownProps> = ({
  onChange,
  value,
}) => {
  const { entities: categoriesById } = useSelector(
    (states: RootState) => states.categories
  );

  const categoryOptions = () => {
    return Object.values(categoriesById).map((c: Category) => {
      return {
        key: c.id,
        text: `${c.name}`,
        value: c.id,
      };
    });
  };

  return (
    <Form.Dropdown
      label="Category"
      name="category"
      placeholder="Select Category"
      selection
      options={categoryOptions()}
      onChange={onChange}
      value={value ? value : undefined}
    />
  );
};

export default CategoryDropdown;
