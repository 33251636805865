import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import colorAPI from "api/colorAPI";
import { Color } from "api/types";
// import { AxiosResponse } from "axios";

export const fetchColors = createAsyncThunk<Color[]>(
  "colors/fetch",
  async (_, { rejectWithValue }) => {
    try {
      const response = await colorAPI.fetch();
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const createColor = createAsyncThunk<Color, Omit<Color, "id">>(
  "colors/create",
  async (colorData, { rejectWithValue }) => {
    try {
      const response = await colorAPI.create(colorData);
      return response;
    } catch (error) {
      if (!error.response) {
        console.log("createColor: throwing");
        throw error;
      }
      console.log("in slice", error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateColor = createAsyncThunk<
  Color,
  { id: number } & Partial<Color>
>("colors/update", async (colorData, { rejectWithValue }) => {
  try {
    const { id, ...fields } = colorData;
    const response = await colorAPI.updateById(id, fields);
    console.log(response);
    return response;
  } catch (error) {
    console.log("updateColorAsyncThunk error: ", error);
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});

export const deleteColor = createAsyncThunk(
  "colors/delete",
  async (id: number, { rejectWithValue }) => {
    try {
      console.log("deleting");
      const response = await colorAPI.deleteById(id);
      console.log("deleted", response);
      return id;
      // return id;
    } catch (error) {
      console.log("deleteColor: error:", error);
      if (!error.response) {
        console.log("deleteColor: throwing");
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

const startLoading = (state: IState) => {
  state.loading = true;
  state.error = null;
};

const loadingFailed = (state: IState, action: PayloadAction<unknown>) => {
  console.log("in reducer", action);
  state.loading = false;
  state.error = action.payload;
};

interface IState {
  entities: Record<number, Color>;
  loading: boolean;
  error: string | null | undefined | any;
}

const initialState: IState = {
  entities: {},
  loading: false,
  error: null,
};

export const colorSlice = createSlice({
  name: "colors",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchColors.pending, startLoading)
      .addCase(fetchColors.rejected, loadingFailed)
      .addCase(fetchColors.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        action.payload.map((color) => (state.entities[color.id] = color));
        // state.entities = action.payload;
      });
    builder
      .addCase(createColor.pending, startLoading)
      .addCase(createColor.rejected, loadingFailed)
      .addCase(createColor.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        const { id } = action.payload;
        state.entities[id] = action.payload;
      });
    builder
      .addCase(updateColor.pending, startLoading)
      .addCase(updateColor.rejected, loadingFailed)
      .addCase(updateColor.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        const { id } = action.payload;
        state.entities[id] = action.payload;
      });
    builder
      .addCase(deleteColor.pending, startLoading)
      .addCase(deleteColor.rejected, loadingFailed)
      .addCase(deleteColor.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        const id = action.payload;
        delete state.entities[id];
      });
  },
});

export default colorSlice.reducer;
