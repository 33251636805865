import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Segment } from "semantic-ui-react";
import { productQueryParams } from "api/productAPI";
import { RootState } from "app/rootReducer";
import { useAppDispatch } from "app/store";
import PageHeader from "components/PageHeader";
import { fetchProducts, resetProducts } from "../productSlice";
import ProductList from "./ProductList";
import ProductQuerySection from "./ProductQuerySection";

const ProductListPage = () => {
  const {
    entities: productsById,
    // error,
    // count,
    loading,
    ordering,
  } = useSelector((state: RootState) => state.products);

  const [queryParams, setQueryParams] = useState<productQueryParams>({});
  const dispatch = useAppDispatch();

  const handleQueryParamsChange = <T extends keyof productQueryParams>(
    fieldName: T
  ) => (data: productQueryParams[T]) => {
    if (fieldName === "category" && data === "all") {
      const { category, ...rest } = queryParams;
      setQueryParams(rest);
      return;
    }
    setQueryParams({
      ...queryParams,
      [fieldName]: data,
    });
  };

  const handleSearch = () => {
    dispatch(fetchProducts({ ...queryParams, page: 1 }));
  };

  const handlePageChange = (targetPage: number) => {
    setQueryParams({ ...queryParams, page: targetPage });
    dispatch(fetchProducts({ ...queryParams, page: targetPage }));
  };

  const getSortedProducts = () => {
    const products = Object.values(productsById);
    switch (ordering) {
      case "style_number":
        return _.orderBy(products, ["styleNumber"], ["asc"]);
      case "-style_number":
        return _.orderBy(products, ["styleNumber"], ["desc"]);
      case "updated_at":
        return _.orderBy(products, ["updatedAt"], ["asc"]);
      case "-updated_at":
      default:
        return _.orderBy(products, ["updatedAt"], ["desc"]);
    }
  };

  useEffect(() => {
    dispatch(fetchProducts({}));
    return () => {
      dispatch(resetProducts());
    };
  }, [dispatch]);

  return (
    <>
      <PageHeader
        header="All Products"
        subheader="Manage products"
        icon="female"
      />
      <Segment.Group>
        <Segment>
          <ProductQuerySection
            queryParams={queryParams}
            onSearch={handleSearch}
            onChange={handleQueryParamsChange}
            onPageChange={handlePageChange}
          />
        </Segment>
        <Segment loading={loading}>
          <ProductList products={getSortedProducts()} />
        </Segment>
        <Segment>
          <ProductQuerySection
            queryParams={queryParams}
            onSearch={handleSearch}
            onChange={handleQueryParamsChange}
            onPageChange={handlePageChange}
            onlyPagination
          />
        </Segment>
      </Segment.Group>
    </>
  );
};

export default ProductListPage;
