import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { RootState } from "app/rootReducer";
import { useAppDispatch } from "app/store";
import { Pack } from "api/types";
import PageHeader from "components/PageHeader";
import SearchBar from "components/SearchBar";
import PackForm from "./PackForm";
import PackList from "./PackList";
import { fetchPacks } from "./packSlice";

const PackPage: React.FC = () => {
  const { entities: packsById, loading /*error*/ } = useSelector(
    (states: RootState) => states.packs
  );
  const [packs, setPacks] = useState<Pack[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const dispatch = useAppDispatch();

  const getSortedPacks = () => {
    let filtered = Object.values(packsById);
    if (searchQuery) {
      filtered = Object.values(packsById).filter((p: Pack) =>
        p.name.startsWith(searchQuery)
      );
    }
    const sorted = _.orderBy(filtered, ["size", "name"], ["desc", "asc"]);
    return sorted;
  };

  const handleSearch = () => {
    setPacks(getSortedPacks());
  };

  useEffect(() => {
    dispatch(fetchPacks());
  }, [dispatch]);

  useEffect(() => {
    setPacks(
      _.orderBy(Object.values(packsById), ["size", "name"], ["desc", "asc"])
    );
  }, [packsById]);

  return (
    <>
      <PageHeader header="Packs" subheader="Manage Packs" icon="settings" />
      <PackForm pack={null} />
      <SearchBar
        onSearch={handleSearch}
        onChange={setSearchQuery}
        value={searchQuery}
        loading={loading}
      />
      <PackList packs={packs} />
    </>
  );
};

export default PackPage;
