import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "app/store";
import { Button, Form, Message } from "semantic-ui-react";
import { login } from "./authSlice";

const LogInForm = (props) => {
  // states
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { loading, error } = useSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const handleLogin = () => {
    console.log("LogInForm: dispatching login");
    dispatch(login({ email, password }));
    console.log("LogInForm: dispatched login");
  };

  return (
    <Form onSubmit={handleLogin}>
      <Message
        error
        visible={error !== null}
        header="Error!"
        list={[
          "Account with given credential was not found",
          "Please try again with correct credential.",
        ]}
      />
      <Form.Field required>
        <label>Email</label>
        <input
          placeholder="email address"
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Form.Field>
      <Form.Field required>
        <label>Password</label>
        <input
          placeholder="password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </Form.Field>
      <Button loading={loading} type="submit">
        Sign In
      </Button>
    </Form>
  );
};

export default LogInForm;
