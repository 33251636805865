import React from "react";
import { Modal, Button } from "semantic-ui-react";

interface DeleteConfirmationProps {
  trigger: any;
  content?: string;
  open: boolean;
  onClose: Function;
  onConfirm: Function;
}

// TODO refactor

const DeleteConfirmation: React.FC<DeleteConfirmationProps> = ({
  trigger,
  content,
  open,
  onClose,
  onConfirm,
}) => {
  const handleConfirm = () => {
    onConfirm();
    onClose();
  };
  return (
    <Modal
      size="mini"
      open={open}
      onClose={(_) => onClose()}
      trigger={trigger}
      dimmer
    >
      <Modal.Header>Are you sure to delete?</Modal.Header>
      {content && <Modal.Content>{content}</Modal.Content>}
      <Modal.Actions>
        <Button negative content="Cancel" onClick={(_) => onClose()} />
        <Button positive content="Confirm" onClick={handleConfirm} />
      </Modal.Actions>
    </Modal>
  );
};

export default DeleteConfirmation;
