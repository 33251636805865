import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAppDispatch } from "app/store";
import { fetchListings, resetListings } from "../listingSlice";
import PageHeader from "components/PageHeader";
import ListingList from "./ListingList";
import { listingQueryParams } from "api/listingAPI";
import { Segment } from "semantic-ui-react";
import ListingQuerySection from "./ListingQuerySection";
// import { useHistory } from "react-router-dom";

interface ListingListPageProps {
  //
}

const ListingListPage: React.FC<ListingListPageProps> = () => {
  const { entities: listingsById, loading, ordering } = useSelector(
    (state: RootState) => state.listings
  );

  const [queryParams, setQueryParams] = useState<listingQueryParams>({});
  const dispatch = useAppDispatch();
  // const history = useHistory();

  const getSortedListings = () => {
    const listings = Object.values(listingsById);
    switch (ordering) {
      case "style_number":
        return _.orderBy(listings, ["styleNumber"], ["asc"]);
      case "-style_number":
        return _.orderBy(listings, ["styleNumber"], ["desc"]);
      case "updated_at":
        return _.orderBy(listings, ["updatedAt"], ["asc"]);
      case "-updated_at":
      default:
        return _.orderBy(listings, ["updatedAt"], ["desc"]);
    }
  };

  const handleQueryParamsChange = <T extends keyof listingQueryParams>(
    fieldName: T
  ) => (data: listingQueryParams[T]) => {
    if (fieldName === "category" && data === "all") {
      const { category, ...rest } = queryParams;
      setQueryParams(rest);
      return;
    }
    setQueryParams({
      ...queryParams,
      [fieldName]: data,
    });
  };

  const handleSearch = () => {
    dispatch(fetchListings({ ...queryParams, page: 1 }));
  };

  const handlePageChange = (targetPage: number) => {
    setQueryParams({ ...queryParams, page: targetPage });
    dispatch(fetchListings({ ...queryParams, page: targetPage }));
  };

  useEffect(() => {
    dispatch(fetchListings({}));
    return () => {
      dispatch(resetListings());
    };
  }, [dispatch]);

  // useEffect(() => {
  //   const qs = stringifyQueryParams(queryParams);
  //   history.replace(`?${qs}`);
  // }, [queryParams, history]);

  return (
    <>
      <PageHeader
        header="All Listings"
        subheader="Manage Listings"
        icon="female"
      />
      <Segment.Group>
        <Segment>
          <ListingQuerySection
            queryParams={queryParams}
            onSearch={handleSearch}
            onChange={handleQueryParamsChange}
            onPageChange={handlePageChange}
          />
        </Segment>
        <Segment loading={loading}>
          <ListingList listings={getSortedListings()} />
        </Segment>
        <Segment>
          <ListingQuerySection
            queryParams={queryParams}
            onSearch={handleSearch}
            onChange={handleQueryParamsChange}
            onPageChange={handlePageChange}
            onlyPagination
          />
        </Segment>
      </Segment.Group>
    </>
  );
};

export default ListingListPage;

// const stringifyQueryParams = (qp: Record<string, any>) => {
//   return Object.keys(qp)
//     .map((k) => `${k}=${qp[k]}`)
//     .join("&");
// };
