import React from "react";
import { useDropzone, DropzoneRootProps } from "react-dropzone";
import styled from "styled-components";
import { Header } from "semantic-ui-react";

const getColor = (props: DropzoneRootProps) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isDragActive) {
    return "#2196f3";
  }
  return "#eeeeee";
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

interface CsvTxtFileDropSectionProps {
  onFileRead: Function;
}

const CsvTxtFileDropSection: React.FC<CsvTxtFileDropSectionProps> = ({
  onFileRead,
}) => {
  const onDrop = (files: File[]) => {
    files.map((file) => parseCSV(file));
  };

  const parseCSV = (file: File) => {
    console.log("filetype:", file.type);
    const reader = new FileReader();
    reader.onload = () => {
      const lines = (reader.result as string)
        .replace(/\0/g, "")
        .split(/\r\n|\n/);
      const items = lines
        .map((line) => line.trim().toUpperCase())
        .filter((line) => line !== "");
      onFileRead(items);
    };
    reader.readAsBinaryString(file);
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    isDragAccept,
  } = useDropzone({
    onDrop,
    // accept: "text/csv,text/plain",
    multiple: false,
  });

  return (
    <>
      <Header size="medium" content="Input CSV file" />
      <ul>
        <li>To submit multiple files, input one at a time. </li>
        <li>
          File extension must be <strong>.csv</strong> or <strong>.txt</strong>
        </li>
        <li>One style number of listing per line.</li>
        <li>
          Maximum of <strong>100</strong> styles at a time.
        </li>
      </ul>
      <section className="container">
        <Container
          {...getRootProps({ isDragAccept, isDragActive, isDragReject })}
        >
          <input {...getInputProps()} />
          <p>Drag n drop a csv file, or click to select a file.</p>
        </Container>
      </section>
    </>
  );
};

export default CsvTxtFileDropSection;
