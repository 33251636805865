import React from "react";
import { Product } from "api/types";
import ProductListItem from "./ProductListItem";
import { Card } from "semantic-ui-react";

interface IProp {
  products: Array<Product>;
}

const ProductList = ({ products }: IProp) => {
  const productItems = products.map((product: Product) => (
    <ProductListItem key={product.id} product={product} />
  ));

  return <Card.Group id="products">{productItems}</Card.Group>;
};

export default ProductList;
