import { useCallback, useLayoutEffect } from "react";
import Quagga from "@ericblade/quagga2";

const barcodeRegex = /^\d{7}-\d{9}$/;

function getMedian(arr) {
  arr.sort((a, b) => a - b);
  const half = Math.floor(arr.length / 2);
  if (arr.length % 2 === 1) {
    return arr[half];
  }
  return (arr[half - 1] + arr[half]) / 2;
}

function getMedianOfCodeErrors(decodedCodes) {
  const errors = decodedCodes
    .filter((x) => x.error !== undefined)
    .map((x) => x.error);
  const medianOfErrors = getMedian(errors);
  return medianOfErrors;
}

const validateCode = (decodedCode) => {
  return decodedCode.length === 17 && barcodeRegex.test(decodedCode);
};

const BarcodeScanner = ({ onDetected, scannerRef }) => {
  const handleProcessed = (result) => {
    const drawingCtx = Quagga.canvas.ctx.overlay;
    const drawingCanvas = Quagga.canvas.dom.overlay;

    if (result && result.boxes) {
      drawingCtx.clearRect(
        0,
        0,
        parseInt(drawingCanvas.getAttribute("width")),
        parseInt(drawingCanvas.getAttribute("height"))
      );
      result.boxes
        .filter((box) => box !== result.box)
        .forEach((box) => {
          Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, {
            color: "green",
            lineWidth: 5,
          });
        });
      if (result.box) {
        Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, {
          color: "blue",
          lineWidth: 3,
        });
      }
    }
    // if (result.codeResult && result.codeResult.code) {
    //   drawingCtx.font = "24px Arial";
    //   drawingCtx.fillText(result.codeResult.code, 10, 20);
    // }
  };

  const errorCheck = useCallback(
    (result) => {
      console.log("detected!!");
      if (!onDetected) {
        console.log("no onDetect give");
        return;
      }
      const err = getMedianOfCodeErrors(result.codeResult.decodedCodes);

      console.log("error rate: ", err);
      // if Quagga is at least 75% certain that it read correctly
      // and pass the validation, then accept the code.
      if (err < 0.25 && validateCode(result.codeResult.code)) {
        console.log("returning: ", result);
        onDetected(result);
        // onDetected(result.codeResult.code);
      }
    },
    [onDetected]
  );

  useLayoutEffect(() => {
    Quagga.init(
      {
        inputStream: {
          type: "LiveStream",
          target: scannerRef.current,
          constraints: {
            width: 1280,
            height: 720,
            aspectRatio: { max: 1.7778 },
          },
          area: {
            top: "45%",
            right: "8%",
            bottom: "45%",
            left: "8%",
          },
        },
        locate: false,
        debug: false,
        decoder: {
          readers: ["code_128_reader"],
        },
      },
      (err) => {
        Quagga.onProcessed(handleProcessed);

        if (err) {
          return console.log("Error starting Quagga:", err);
        }
        if (scannerRef && scannerRef.current) {
          Quagga.start();
        }
      }
    );

    Quagga.onDetected(errorCheck);
    return () => {
      Quagga.offDetected(errorCheck);
      Quagga.offProcessed(handleProcessed);
      Quagga.stop();
    };
  });

  return null;
};

export default BarcodeScanner;
