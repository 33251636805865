import React, { useState, useEffect } from "react";
import { Form, Modal, Button } from "semantic-ui-react";
import { useAppDispatch } from "app/store";
import { Color } from "api/types";
import { updateColor, createColor } from "./colorSlice";

interface ColorDetailFormProps {
  color: Color | null;
}

const ColorForm = ({ color }: ColorDetailFormProps) => {
  const dispatch = useAppDispatch();
  const [name, setName] = useState("");
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (color) {
      setName(color.name);
    }
  }, [color]);

  const close = () => {
    // revert changes to initial color value on closing
    color ? setName(color.name) : setName("");
    setIsOpen(false);
  };
  const open = () => {
    setIsOpen(true);
  };

  const handleSubmit = () => {
    console.log(`submitting color! ${color ? "update" : "create"}`);
    if (color) {
      dispatch(updateColor({ id: color.id, name }));
    } else {
      dispatch(createColor({ name }));
    }
    close();
  };

  const getTrigger = () => {
    return color ? (
      <span
        style={{ color: "blue", fontSize: "0.9rem", cursor: "pointer" }}
        onClick={open}
      >
        Edit
      </span>
    ) : (
      <Button content="Create New Color" onClick={open} primary />
    );
  };

  return (
    <Modal
      as={Form}
      open={isOpen}
      onClose={close}
      size="tiny"
      closeOnDimmerClick={false}
      trigger={getTrigger()}
      onSubmit={handleSubmit}
    >
      <Modal.Header content={color ? "Edit Color" : "Create New Color"} />
      <Modal.Content>
        <Form.Input
          label="Color name"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value.toUpperCase())}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" content="Cancel" onClick={close} />
        <Button type="submit" content="Submit" primary />
      </Modal.Actions>
    </Modal>
  );
};

export default ColorForm;
