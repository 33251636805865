import React from "react";
import { Route, Redirect } from "react-router-dom";

const AuthRoute = ({ component: Component, user, ...rest }) => {
  const { userId: isAuthenticated } = user;
  return (
    <Route
      {...rest}
      render={(routerProps) =>
        !isAuthenticated ? <Component {...routerProps} /> : <Redirect to="/" />
      }
    />
  );
};

export default AuthRoute;
