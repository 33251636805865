import React from "react";
import { Input, Form, Button } from "semantic-ui-react";

interface IProps {
  onSearch: Function;
  onChange: Function;
  value: string | undefined;
  loading: boolean;
  placeholder?: string;
}

const SearchBar: React.FC<IProps> = ({
  onChange,
  onSearch,
  value,
  loading,
  placeholder,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.currentTarget.value;
    onChange(text.toUpperCase());
  };

  return (
    <>
      <Form onSubmit={() => onSearch()}>
        <Input
          type="text"
          placeholder={placeholder ? placeholder : "Search..."}
          value={value ? value : ""}
          onChange={handleChange}
          disabled={loading}
          action
        >
          <input />
          <Button type="submit" icon="search" loading={loading} primary />
        </Input>
      </Form>
    </>
  );
};

export default SearchBar;
