import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import { TokenStorage } from "./tokenStorage";
import { camelizeKeys, decamelizeKeys } from "humps";
import store from "app/store";
import { logout } from "features/auth/authSlice";

export const api = axios.create({
  // baseURL: "http://192.168.254.15:8000/api/",
  baseURL: "https://api.heimishusa.com/api/",
});

// Conversion between camelCase and snake_case from interceptor from below source:
// https://medium.com/javascript-in-plain-english/configuring-a-camelcase-to-snake-case-parser-with-axios-9fa34fd3b16f

// Request interceptor for API calls
api.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const authConfig = TokenStorage.getAuthentication();
    const newConfig = { ...config, ...authConfig };
    console.log(`requesting to: ${newConfig.baseURL}${newConfig.url}`);

    if (config.headers["Content-Type"] === "multipart/form-data") {
      newConfig.headers["Content-Type"] = "multipart/form-data";
      return newConfig;
    }

    newConfig.headers["Content-Type"] = "application/json";

    if (config.params) {
      newConfig.params = decamelizeKeys(config.params);
    }

    if (config.data) {
      newConfig.data = decamelizeKeys(config.data);
    }

    return newConfig;
  },
  (error) => {
    console.log("request interceptor: error", error);
    Promise.reject(error);
  }
);

// Response interceptor for API calls
api.interceptors.response.use(
  (response: AxiosResponse) => {
    if (
      response.data &&
      response.headers["content-type"] === "application/json"
    ) {
      response.data = camelizeKeys(response.data);
    }
    return response;
  },
  async (error) => {
    console.log("AxiosInterceptor: Error");
    console.log("AxiosInterceptor: Error.response", error.response);
    // Return any error which is not due to authentication.
    if (error.response.status !== 401) {
      console.log("AxiosInterceptor: None 401 Error");
      return Promise.reject(error);
    }

    // Return error if invalid credential/blocked user
    if (error.config.url.includes("token/obtain/")) {
      console.log("AxiosInterceptor: Invalid credential");
      return Promise.reject(error);
    }

    // Logout user if token refresh didn't work.
    if (error.config.url.includes("token/refresh/")) {
      console.log("AxiosInterceptor: refresh token expired");
      store.dispatch(logout());
      return Promise.reject(error);
    }

    const token = await TokenStorage.getNewToken(api);
    console.log("retrying with new token", error.config);
    const config = error.config;
    if (config.data instanceof FormData) {
      config.headers["Content-Type"] = "multipart/form-data";
    }
    config.headers["Authorization"] = `Bearer ${token}`;
    return api.request(config);
  }
);

export default {
  get: api.get,
  post: api.post,
  put: api.put,
  patch: api.patch,
  delete: api.delete,
};
