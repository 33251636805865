import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAppDispatch } from "app/store";
import { useParams } from "react-router-dom";
import { fetchListingById, resetListings } from "../listingSlice";
import PageHeader from "components/PageHeader";
import ListingForm from "./ListingForm";
import { resetProducts } from "features/product/productSlice";

interface ListingDetailPageProps {
  //
}

const ListingDetailPage: React.FC<ListingDetailPageProps> = () => {
  const { entities: listingsById, loading /*error*/ } = useSelector(
    (state: RootState) => state.listings
  );
  const dispatch = useAppDispatch();
  const { id } = useParams();

  useEffect(() => {
    if (id !== "new") {
      dispatch(fetchListingById(id));
    }
    return () => {
      dispatch(resetProducts());
      dispatch(resetListings());
    };
  }, [dispatch, id]);

  const getListing = () => {
    if (id === "new") {
      return null;
    }
    return listingsById[id];
  };

  const header = id === "new" ? "Create New Listing" : "Edit Listing";

  return (
    <>
      <PageHeader header={header} subheader="Manage Listing" icon="female" />
      <ListingForm listing={getListing()} loading={loading} />
    </>
  );
};

export default ListingDetailPage;
