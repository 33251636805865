import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import productAPI, {
  ProductFormValues,
  productQueryParams,
} from "api/productAPI";
import { Product, FetchResponse, PageSize } from "api/types";

export const fetchProducts = createAsyncThunk<
  FetchResponse<Product>,
  productQueryParams
>("products/fetch", async (query, { rejectWithValue }) => {
  try {
    return await productAPI.getProducts(query);
  } catch (error) {
    if (!error.response) {
      console.log("fetchProductList: throwing");
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});

export const fetchProductById = createAsyncThunk(
  "products/fetchById",
  async (id: number, { rejectWithValue }) => {
    try {
      return await productAPI.getProductById(id);
    } catch (error) {
      if (!error.response) {
        console.log("fetchProductDetail: throwing");
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const createProduct = createAsyncThunk(
  "products/create",
  async (productData: ProductFormValues, { rejectWithValue }) => {
    try {
      return await productAPI.createProduct(productData);
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateProduct = createAsyncThunk(
  "products/update",
  async (
    productData: { id: number } & ProductFormValues,
    { rejectWithValue }
  ) => {
    try {
      const { id } = productData;
      return await productAPI.updateProduct(id, productData);
    } catch (error) {
      if (!error.response) {
        console.log("updateProduct: throwing");
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteProduct = createAsyncThunk(
  "products/delete",
  async (id: number, { rejectWithValue }) => {
    try {
      await productAPI.deleteProduct(id);
      return id;
    } catch (error) {
      if (!error.response) {
        console.log("deleteProduct: throwing");
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const removeProductVariations = createAsyncThunk<
  Product,
  { id: number; data: Array<number> }
>("products/removeVariations", async (params, { rejectWithValue }) => {
  try {
    const { id, data } = params;
    return await productAPI.removeVariations(id, data);
  } catch (error) {
    if (!error.response) {
      console.log("removeVariations: throwing");
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});

export const removeImages = createAsyncThunk<
  Product,
  { id: number; data: Array<number> }
>("products/removeImages", async (params, { rejectWithValue }) => {
  try {
    const { id, data } = params;
    return await productAPI.removeImages(id, data);
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});

interface IState {
  entities: Record<number, Product>;
  count: number;
  page: number;
  pageSize: PageSize;
  pageCount: number;
  ordering: productQueryParams["ordering"];
  loading: boolean;
  error: string | null | unknown;
}

const startLoading = (state: IState) => {
  state.loading = true;
  state.error = null;
};

const loadingFailed = (state: IState, action: PayloadAction<unknown>) => {
  state.loading = false;
  state.error = action.payload;
};

const initialState: IState = {
  entities: {},
  count: 0,
  page: 1,
  pageSize: 20,
  pageCount: 0,
  ordering: "-updated_at",
  loading: false,
  error: null,
};

export const productSlice = createSlice({
  name: "products",
  initialState: initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, startLoading)
      .addCase(fetchProducts.rejected, loadingFailed)
      .addCase(fetchProducts.fulfilled, (state, action) => {
        if (state.loading) {
          const { count, pageCount, results } = action.payload;
          const { page, pageSize, ordering } = action.meta.arg;
          state.loading = false;
          state.error = null;
          state.count = count;
          state.page = page ? page : state.page;
          state.pageSize = pageSize ? pageSize : state.pageSize;
          state.ordering = ordering ? ordering : state.ordering;
          state.pageCount = pageCount;
          state.entities = {};
          results.map(
            (product: Product) => (state.entities[product.id] = product)
          );
        }
      });
    builder
      .addCase(fetchProductById.pending, startLoading)
      .addCase(fetchProductById.rejected, loadingFailed)
      .addCase(fetchProductById.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        const { id } = action.payload;
        state.entities[id] = action.payload;
      });
    builder
      .addCase(createProduct.pending, startLoading)
      .addCase(createProduct.rejected, loadingFailed)
      .addCase(createProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        const { id } = action.payload;
        state.entities[id] = action.payload;
      });
    builder
      .addCase(updateProduct.pending, startLoading)
      .addCase(updateProduct.rejected, loadingFailed)
      .addCase(updateProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        const { id } = action.payload;
        state.entities[id] = action.payload;
      });
    builder
      .addCase(deleteProduct.pending, startLoading)
      .addCase(deleteProduct.rejected, loadingFailed)
      .addCase(deleteProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        const id = action.payload;
        delete state.entities[id];
      });
    builder
      .addCase(removeProductVariations.pending, startLoading)
      .addCase(removeProductVariations.rejected, loadingFailed)
      .addCase(removeProductVariations.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        const { id } = action.payload;
        state.entities[id] = action.payload;
      });
    builder
      .addCase(removeImages.pending, startLoading)
      .addCase(removeImages.rejected, loadingFailed)
      .addCase(removeImages.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        const { id } = action.payload;
        state.entities[id] = action.payload;
      });
  },
});

export const { reset: resetProducts } = productSlice.actions;
export default productSlice.reducer;
