import React from "react";
import { Listing } from "api/types";
import { Card } from "semantic-ui-react";
import ListingListItem from "./ListingListItem";

interface ListingListProps {
  listings: Listing[];
}

const ListingList: React.FC<ListingListProps> = ({ listings }) => {
  return (
    <>
      <Card.Group id="listings">
        {listings.map((listing: Listing) => (
          <ListingListItem key={listing.id} listing={listing} />
        ))}
      </Card.Group>
    </>
  );
};

export default ListingList;
