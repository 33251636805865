import React from "react";
import { CurrentUser } from "api/types";
import { useAppDispatch } from "app/store";
import { Sidebar, Menu, Icon } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import { logout } from "features/auth/authSlice";

interface NavBarProps {
  user: CurrentUser;
}

const NavBar: React.FC<NavBarProps> = ({ user }) => {
  const { userId: isAuthenticated } = user;
  const dispatch = useAppDispatch();

  if (!isAuthenticated) {
    return <></>;
  }

  return (
    <Sidebar
      id="sidebar"
      as={Menu}
      animation="push"
      direction="left"
      icon="labeled"
      inverted
      vertical
      visible={true}
      width="thin"
    >
      <Menu.Item as={NavLink} exact to="/">
        <Icon name="home" />
        Home
      </Menu.Item>
      <Menu.Item>
        Products
        <Menu.Menu>
          <Menu.Item content="All Products" as={NavLink} exact to="/products" />
          <Menu.Item
            content="Create New Product"
            as={NavLink}
            exact
            to="/products/new"
          />
        </Menu.Menu>
      </Menu.Item>
      <Menu.Item>
        Listings
        <Menu.Menu>
          <Menu.Item content="All Listings" as={NavLink} exact to="/listings" />
          <Menu.Item
            content="Create New Listing"
            as={NavLink}
            exact
            to="/listings/new"
          />
        </Menu.Menu>
      </Menu.Item>
      <Menu.Item>
        LiveStream Sale
        <Menu.Menu>
          <Menu.Item
            content="On Air Overview"
            as={NavLink}
            exact
            to="/livestream/overview"
          />
          <Menu.Item
            content="Selections"
            as={NavLink}
            exact
            to="/livestream/selections"
          />
        </Menu.Menu>
      </Menu.Item>
      <Menu.Item>
        Orders
        <Menu.Menu>
          <Menu.Item content="All Orders" as={NavLink} exact to="/orders" />
          <Menu.Item
            content="Create New Order"
            as={NavLink}
            exact
            to="/orders/new"
          />
          <Menu.Item content="Invoices" as={NavLink} exact to="/invoices" />
        </Menu.Menu>
      </Menu.Item>
      <Menu.Item>
        Customers
        <Menu.Menu>
          <Menu.Item
            content="All Customers"
            as={NavLink}
            exact
            to="/customers"
          />
          <Menu.Item
            content="Create New Customers"
            as={NavLink}
            exact
            to="/customers/new"
          />
          <Menu.Item
            content="Store Credit"
            as={NavLink}
            exact
            to="/store_credits"
          />
        </Menu.Menu>
      </Menu.Item>
      <Menu.Item>
        Tools
        <Menu.Menu>
          <Menu.Item content="Linesheet" as={NavLink} exact to="/linesheets" />
          <Menu.Item content="Barcode" as={NavLink} exact to="/barcodes" />
          {/* <Menu.Item content="QR Code" as={NavLink} exact to="/qrcodes" /> */}
          <Menu.Item
            content="Marketing Message"
            as={NavLink}
            exact
            to="/marketing/send_message"
          />
        </Menu.Menu>
      </Menu.Item>
      <Menu.Item>
        Misc.
        <Menu.Menu>
          <Menu.Item content="Categories" as={NavLink} exact to="/categories" />
          <Menu.Item content="Colors" as={NavLink} exact to="/colors" />
          <Menu.Item content="Packs" as={NavLink} exact to="/packs" />
          <Menu.Item content="Banners" as={NavLink} exact to="/banners" />
        </Menu.Menu>
      </Menu.Item>
      {/* <Menu.Item content="Test" as={NavLink} exact to="/test" /> */}
      {/* <Accordion as={Menu.Item} inverted>
       *   <Accordion.Title active={true}>Livestream</Accordion.Title>
       *   <Accordion.Content as={Menu.Menu} active={true}>
       *     <Menu.Item as={NavLink} to="/livestream/overview">
       *       OnAir Overview
       *     </Menu.Item>
       *   </Accordion.Content>
       * </Accordion>
       * <Menu.Item as="a">+</Menu.Item>
       * <Menu.Item as="a">+</Menu.Item>
       * <Menu.Item as={NavLink} to="/test">
       *   Test
       * </Menu.Item> */}
      {isAuthenticated ? (
        <Menu.Item
          content="Sign Out"
          as={NavLink}
          to="/login"
          id="logout-btn"
          onClick={() => dispatch(logout())}
        />
      ) : (
        <Menu.Item
          content="Sign In"
          as={NavLink}
          exact
          to="/login"
          id="login-btn"
        />
      )}
    </Sidebar>
  );
};

// top bar

// const NavBar: React.FC<NavBarProps> = ({ user }) => {
//   const { userId: isAuthenticated } = user;
//   const dispatch = useAppDispatch();
//
//   return (
//     <>
//       <Menu fixed="top" inverted>
//         <Container>
//           <Menu.Item as={NavLink} exact to="/" content="Home" icon="home" />
//           <Dropdown item simple text="Products">
//             <Dropdown.Menu>
//               <Dropdown.Item
//                 as={NavLink}
//                 content="All Products"
//                 to="/products"
//                 exact
//               />
//               <Dropdown.Item
//                 as={NavLink}
//                 content="Create New Product"
//                 to="/products/new"
//                 exact
//               />
//             </Dropdown.Menu>
//           </Dropdown>
//         </Container>
//       </Menu>
//     </>
//   );
// };
export default NavBar;
