import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useAppDispatch } from "app/store";
import { Product, Size } from "api/types";
import {
  Segment,
  Form,
  InputProps,
  DropdownProps,
  CheckboxProps,
} from "semantic-ui-react";
import {
  ProductFormValues,
  ProductVariationFormValues,
  ImageFormValues,
} from "api/productAPI";
import ItemInfoFormSection from "./ItemInfoFormSection";
import VariationsFormSection from "./VariationsFormSection";
import { fetchPacks } from "features/pack/packSlice";
import { useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import ImagesFormSection from "./ImagesFormSection";
import {
  updateProduct,
  createProduct,
  removeProductVariations,
  removeImages,
} from "../productSlice";
import { useHistory } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";

interface ProductFormProps {
  product: Product | null;
  loading: boolean;
}

const initialData: ProductFormValues = {
  styleNumber: "",
  name: "",
  description: "",
  category: null,
  isOnesize: false,
  regularPrice: 0,
  plusPrice: 0,
  onesizePrice: 0,
  regularPack: null,
  plusPack: null,
  onesizePack: null,
  variations: [],
  images: [],
};

const ProductForm: React.FC<ProductFormProps> = ({ product, loading }) => {
  const { entities: packsById } = useSelector(
    (state: RootState) => state.packs
  );
  const { entities: categoriesById } = useSelector(
    (state: RootState) => state.categories
  );
  const [productData, setProductData] = useState<ProductFormValues>(
    initialData
  );

  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(fetchPacks());
  }, [dispatch]);

  useEffect(() => {
    if (product) {
      const {
        id,
        createdAt,
        updatedAt,
        regularPrice,
        plusPrice,
        onesizePrice,
        ...fields
      } = product;
      setProductData({
        regularPrice: Number((regularPrice / 100).toFixed(2)),
        plusPrice: Number((plusPrice / 100).toFixed(2)),
        onesizePrice: Number((onesizePrice / 100).toFixed(2)),
        ...fields,
      });
    } else {
      setProductData(initialData);
    }
  }, [product]);

  const handleChange = (fieldName: string) => (
    _: React.SyntheticEvent<HTMLElement, Event>,
    data: InputProps | DropdownProps | CheckboxProps
  ) => {
    switch (fieldName) {
      case "isOnesize":
        setProductData({ ...productData, [fieldName]: data.checked });
        break;
      case "regularPrice":
      case "plusPrice":
      case "onesizePrice":
        handlePriceChange(fieldName, data.value);
        break;
      case "regularPack":
      case "plusPack":
      case "onesizePack":
        handlePackChange(fieldName, data.value);
        break;
      case "category":
        handleCategoryChange(fieldName, data.value);
        break;
      case "styleNumber":
        setProductData({
          ...productData,
          [fieldName]: data.value.toUpperCase(),
        });
        break;
      default:
        setProductData({ ...productData, [fieldName]: data.value });
    }
  };

  const handlePackChange = (fieldName: string, id: number) => {
    const pack = id === 0 ? null : packsById[id];
    setProductData({ ...productData, [fieldName]: pack });
  };

  const handleCategoryChange = (fieldName: string, id: number) => {
    const category = categoriesById[id];
    setProductData({ ...productData, [fieldName]: category });
  };

  const handlePriceChange = (fieldName: string, value: string) => {
    const re = /^\d+(\.\d{0,2})?$/;
    if (value === "" || re.test(value)) {
      setProductData({
        ...productData,
        [fieldName]: value,
      });
    }
  };

  const handleVariationAdd = (variation: ProductVariationFormValues) => {
    if (_.find(productData.variations, variation)) {
      // TODO: may need to check when updating.
      return;
    }
    let newVariations = [...productData.variations, variation];
    newVariations = _.orderBy(
      newVariations,
      ["color", "size"],
      ["asc", "desc"]
    );
    setProductData({
      ...productData,
      variations: newVariations as ProductVariationFormValues[],
    });
  };

  const handleVariationRemoval = (
    variationId: number | undefined,
    color: string,
    size: Size
  ) => {
    const newVariations = productData.variations.filter(
      (v) => !(v.size === size && v.color === color)
    );
    setProductData({
      ...productData,
      variations: newVariations,
    });
    if (product && variationId) {
      dispatch(
        removeProductVariations({ id: product.id, data: [variationId] })
      );
    }
  };

  const handleImageAddition = (files: File[]) => {
    console.log("adding file", files);
    const imagesLength = productData.images.length;
    let position = imagesLength === 0 ? 0 : imagesLength + 1;
    const newImages: ImageFormValues[] = files.map((file: File) => {
      return {
        color: "",
        position: position++,
        image: file,
        thumbnail: file,
      };
    });
    setProductData({
      ...productData,
      images: [...productData.images, ...newImages],
    });
  };

  const handleImageRemoval = (
    imageId: number | undefined,
    position: number
  ) => {
    const newImages = productData.images.filter(
      (img) => img.position !== position
    );
    setProductData({
      ...productData,
      images: newImages,
    });
    if (product && imageId) {
      dispatch(removeImages({ id: product.id, data: [imageId] }));
    }
  };

  const handleImageColorChange = (position: number, color: string) => {
    const newImages = _.cloneDeep(productData.images);
    newImages[position].color = color;
    setProductData({
      ...productData,
      images: newImages,
    });
  };

  const handleImageReposition = (
    currPosition: number,
    targetPosition: number
  ) => {
    const image = productData.images[currPosition];
    if (image) {
      const { images, ...rest } = productData;
      const newImages = _.cloneDeep(images);
      const currImage = newImages.splice(currPosition, 1);
      newImages.splice(targetPosition, 0, currImage[0]);
      newImages.forEach((image, index) => {
        image.position = index;
      });
      setProductData({
        ...rest,
        images: newImages,
      });
    }
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (product) {
      dispatch(updateProduct({ ...productData, id: product.id }));
    } else {
      dispatch(createProduct(productData))
        .then(unwrapResult)
        .then((product) => {
          history.push(`/products/${product.id}`);
        });
    }
  };

  return (
    <Form onSubmit={(e) => e.preventDefault()} loading={loading}>
      <Segment.Group>
        <Segment>
          <ItemInfoFormSection item={productData} onChange={handleChange} />
        </Segment>
        <Segment>
          <VariationsFormSection
            item={productData}
            onChange={handleChange}
            onVariationAdd={handleVariationAdd}
            onVariationRemoval={handleVariationRemoval}
          />
        </Segment>
        <Segment>
          <ImagesFormSection
            item={productData}
            onImageColorChange={handleImageColorChange}
            onImageAddition={handleImageAddition}
            onImageRemoval={handleImageRemoval}
            onImageReposition={handleImageReposition}
          />
        </Segment>
      </Segment.Group>
      <Form.Group>
        <Form.Button
          type="button"
          content="Cancel"
          onClick={() => history.push("/products")}
          color="orange"
        />
        <Form.Button
          type="submit"
          content="Submit"
          onClick={handleSubmit}
          loading={loading}
          primary
        />
      </Form.Group>
    </Form>
  );
};

export default ProductForm;
