import React, { useEffect } from "react";
import JsBarcode from "jsbarcode";
import styled from "styled-components";

const SVG = styled.svg`
  width: 200px;
`;

interface BarcodeProps {
  value: string;
}

const Barcode: React.FC<BarcodeProps> = ({ value }) => {
  useEffect(() => {
    const elemId = `#b-${value}`;
    try {
      JsBarcode(elemId, value, {
        height: 40,
        width: 2,
        displayValue: true,
        margin: 0,
        fontSize: 12,
        textMargin: 0,
        font: "monospace",
      });
    } catch (e) {
      console.error("error", e);
    }
  }, [value]);

  return <SVG id={"b-" + value} />;
};

export default Barcode;
