import React from "react";
import { Pagination as SPagination } from "semantic-ui-react";

interface PaginationProps {
  pageCount: number;
  currentPage: number;
  onChange: Function;
  loading: boolean;
}

const Pagination: React.FC<PaginationProps> = ({
  pageCount,
  currentPage,
  onChange,
  loading,
}) => {
  return (
    <SPagination
      activePage={currentPage}
      boundaryRange={1}
      siblingRange={1}
      totalPages={pageCount}
      firstItem={null}
      lastItem={null}
      onPageChange={(_, data) => onChange(data.activePage)}
      disabled={loading}
      size="small"
    />
  );
};

export default Pagination;
