import React from "react";
import { List } from "semantic-ui-react";
import { Pack } from "api/types";
import PackListItem from "./PackListItem";

interface PackListProps {
  packs: Pack[];
}

const PackList: React.FC<PackListProps> = ({ packs }) => {
  const packItems = packs.map((pack) => (
    <PackListItem key={pack.id} pack={pack} />
  ));

  return <List horizontal>{packItems}</List>;
};

export default PackList;
