import { createAsyncThunk, PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Listing, FetchResponse, PageSize } from "api/types";
import listingAPI, {
  listingQueryParams,
  ListingFormData,
} from "api/listingAPI";

export const fetchListings = createAsyncThunk<
  FetchResponse<Listing>,
  listingQueryParams
>("listings/fetch", async (query, { rejectWithValue }) => {
  try {
    return await listingAPI.getListings(query);
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});

export const fetchListingById = createAsyncThunk(
  "listings/fetchById",
  async (id: number, { rejectWithValue }) => {
    try {
      return await listingAPI.getListingById(id);
    } catch (error) {
      if (!error.response) {
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const createListing = createAsyncThunk<Listing, ListingFormData>(
  "listings/create",
  async (listingData, { rejectWithValue }) => {
    try {
      return await listingAPI.createListing(listingData);
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateListing = createAsyncThunk<
  Listing,
  { id: number } & ListingFormData
>("listings/update", async (listingData, { rejectWithValue }) => {
  try {
    const { id } = listingData;
    return await listingAPI.updateListing(id, listingData);
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});

export const deleteListing = createAsyncThunk<number, number>(
  "listings/delete",
  async (id, { rejectWithValue }) => {
    try {
      await listingAPI.deleteListing(id);
      return id;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const removeListingVariations = createAsyncThunk<
  Listing,
  { id: number; data: Array<number> }
>("listings/removeVariations", async ({ id, data }, { rejectWithValue }) => {
  try {
    return await listingAPI.removeVariations(id, data);
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});

interface IState {
  entities: Record<number, Listing>;
  count: number;
  page: number;
  pageSize: PageSize;
  pageCount: number;
  ordering: listingQueryParams["ordering"];
  loading: boolean;
  error: string | null | unknown;
}

const startLoading = (state: IState) => {
  state.loading = true;
  state.error = null;
};

const loadingFailed = (state: IState, action: PayloadAction<unknown>) => {
  state.loading = false;
  state.error = action.payload;
};

const initialState: IState = {
  entities: {},
  count: 0,
  page: 1,
  pageSize: 20,
  pageCount: 0,
  ordering: "-updated_at",
  loading: false,
  error: null,
};

export const listingSlice = createSlice({
  name: "listings",
  initialState: initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchListings.pending, startLoading)
      .addCase(fetchListings.rejected, loadingFailed)
      .addCase(fetchListings.fulfilled, (state, action) => {
        if (state.loading) {
          const { count, pageCount, results } = action.payload;
          const { page, pageSize, ordering } = action.meta.arg;
          state.loading = false;
          state.error = null;
          state.count = count;
          state.page = page ? page : state.page;
          state.pageSize = pageSize ? pageSize : state.pageSize;
          state.ordering = ordering ? ordering : state.ordering;
          state.pageCount = pageCount;
          state.entities = {};
          results.map(
            (listing: Listing) => (state.entities[listing.id] = listing)
          );
        }
      });
    builder
      .addCase(fetchListingById.pending, startLoading)
      .addCase(fetchListingById.rejected, loadingFailed)
      .addCase(fetchListingById.fulfilled, (state, action) => {
        if (state.loading) {
          state.loading = false;
          state.error = null;
          const { id } = action.payload;
          state.entities[id] = action.payload;
        }
      });
    builder
      .addCase(createListing.pending, startLoading)
      .addCase(createListing.rejected, loadingFailed)
      .addCase(createListing.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        const { id } = action.payload;
        state.entities[id] = action.payload;
      });
    builder
      .addCase(updateListing.pending, startLoading)
      .addCase(updateListing.rejected, loadingFailed)
      .addCase(updateListing.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        const { id } = action.payload;
        state.entities[id] = action.payload;
      });
    builder
      .addCase(deleteListing.pending, startLoading)
      .addCase(deleteListing.rejected, loadingFailed)
      .addCase(deleteListing.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        const id = action.payload;
        delete state.entities[id];
      });
    builder
      .addCase(removeListingVariations.pending, startLoading)
      .addCase(removeListingVariations.rejected, loadingFailed)
      .addCase(removeListingVariations.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        const { id } = action.payload;
        state.entities[id] = action.payload;
      });
  },
});

export const { reset: resetListings } = listingSlice.actions;
export default listingSlice.reducer;
