import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authAPI from "api/authAPI";
import { LoginCredential, CurrentUser } from "api/types";

// Async Thunk Actions
export const login = createAsyncThunk<CurrentUser, LoginCredential>(
  "auth/login",
  async ({ email, password }) => {
    const { userId, firstName, lastName, role } = await authAPI.login(
      email,
      password
    );
    return { userId, firstName, lastName, role };
  }
);

interface State {
  user: CurrentUser;
  loading: boolean;
  error: string | undefined | null;
}

// Slice
const initialState: State = {
  user: authAPI.getCurrentUser(),
  loading: false,
  error: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    logout: (state) => {
      authAPI.logout();
      state.user = authAPI.getCurrentUser();
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      if (!state.loading) {
        state.loading = true;
        state.error = null;
      }
    });
    builder.addCase(login.fulfilled, (state, action) => {
      if (state.loading) {
        state.loading = false;
        state.user = action.payload;
        state.error = null;
      }
    });
    builder.addCase(login.rejected, (state, action) => {
      // TODO
      if (state.loading) {
        state.loading = false;
        state.error = action.error.message;
      }
    });
  },
});

// Actions
export const { logout } = authSlice.actions;

// Reducers
export default authSlice.reducer;
