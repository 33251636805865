import React from "react";
import { useAppDispatch } from "app/store";
import { logout } from "./authSlice";

const LogOutPage: React.FC = () => {
  const dispatch = useAppDispatch();
  console.log("LogOutPage: dispatching logout");
  dispatch(logout());
  console.log("LogOutPage: dispatched logout");
  return <h1>Logged out</h1>;
};

export default LogOutPage;
