import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import packAPI from "api/packAPI";
import { Pack } from "api/types";

export const fetchPacks = createAsyncThunk<Pack[]>(
  "packs/fetch",
  async (_, { rejectWithValue }) => {
    try {
      const response = await packAPI.fetch();
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const createPack = createAsyncThunk<Pack, Omit<Pack, "id">>(
  "packs/create",
  async (packData, { rejectWithValue }) => {
    try {
      const response = await packAPI.create(packData);
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const updatePack = createAsyncThunk<
  Pack,
  { id: number } & Partial<Pack>
>("packs/update", async (packData, { rejectWithValue }) => {
  try {
    const { id } = packData;
    const response = await packAPI.updateById(id, packData);
    return response;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});

export const deletePack = createAsyncThunk(
  "packs/delete",
  async (id: number, { rejectWithValue }) => {
    try {
      await packAPI.deleteById(id);
      return id;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

const startLoading = (state: IState) => {
  state.loading = true;
  state.error = null;
};

const loadingFailed = (state: IState, action: PayloadAction<unknown>) => {
  state.loading = false;
  state.error = action.payload;
};

interface IState {
  entities: Record<number, Pack>;
  loading: boolean;
  error: string | null | undefined | any;
}

const initialState: IState = {
  entities: {},
  loading: false,
  error: null,
};

export const packSlice = createSlice({
  name: "packs",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPacks.pending, startLoading)
      .addCase(fetchPacks.rejected, loadingFailed)
      .addCase(fetchPacks.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        action.payload.map((pack: Pack) => (state.entities[pack.id] = pack));
        // state.entities = action.payload;
      });
    builder
      .addCase(createPack.pending, startLoading)
      .addCase(createPack.rejected, loadingFailed)
      .addCase(createPack.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        const { id } = action.payload;
        state.entities[id] = action.payload;
      });
    builder
      .addCase(updatePack.pending, startLoading)
      .addCase(updatePack.rejected, loadingFailed)
      .addCase(updatePack.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        const { id } = action.payload;
        state.entities[id] = action.payload;
      });
    builder
      .addCase(deletePack.pending, startLoading)
      .addCase(deletePack.rejected, loadingFailed)
      .addCase(deletePack.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        const id = action.payload;
        delete state.entities[id];
      });
  },
});

export default packSlice.reducer;
