import React from "react";
import { Header, Card, Image as SImage } from "semantic-ui-react";
import { Image } from "api/types";
import { ListingFormData } from "api/listingAPI";

interface ListingFormImageSectionProps {
  item: ListingFormData;
}

const ListingFormImageSection: React.FC<ListingFormImageSectionProps> = ({
  item,
}) => {
  return (
    <>
      <Header size="medium">Images</Header>
      <Header size="small">
        To edit images, edit the parent Product instance.
      </Header>
      <Card.Group>
        {item.images &&
          item.images.map((image: Image) => (
            <Card key={image.id} style={{ width: 150 }}>
              <SImage
                src={image.thumbnail}
                style={{ width: 150 }}
                ui={false}
                wrapped
              />
              <Card.Content>
                {image.color ? image.color : "No Color"}
              </Card.Content>
            </Card>
          ))}
      </Card.Group>
    </>
  );
};

export default ListingFormImageSection;
