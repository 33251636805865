import React from "react";
import { useSelector } from "react-redux";
import { Select, Grid, Checkbox } from "semantic-ui-react";
import styled from "styled-components";
import { listingQueryParams } from "api/listingAPI";
import { Category } from "api/types";
import { RootState } from "app/rootReducer";
import SearchBar from "components/SearchBar";
import Pagination from "components/Pagination";

const Label = styled.label`
  margin-right: 1em;
`;
const orderingOptions = [
  { key: "-updated_at", text: "Updated: Recent", value: "-updated_at" },
  { key: "updated_at", text: "Updated: Old", value: "updated_at" },
  { key: "style_number", text: "Style Number: A-Z", value: "style_number" },
  { key: "-style_number", text: "Style Number: Z-A", value: "-style_number" },
];

const pageSizeOptions = [
  { key: 20, text: "20", value: 20 },
  { key: 50, text: "50", value: 50 },
  { key: 100, text: "100", value: 100 },
];

interface ListingQuerySectionProps {
  queryParams: listingQueryParams;
  onSearch: Function;
  onChange: Function;
  onPageChange: Function;
  onlyPagination?: boolean;
}

const ListingQuerySection: React.FC<ListingQuerySectionProps> = ({
  queryParams,
  onSearch,
  onChange,
  onPageChange,
  onlyPagination,
}) => {
  const {
    search,
    category,
    ordering,
    pageSize,
    isInstock,
    isMain,
  } = queryParams;
  const { entities: categoriesById } = useSelector(
    (state: RootState) => state.categories
  );
  const { page, pageCount, loading } = useSelector(
    (state: RootState) => state.listings
  );

  const categoryOptions = () => {
    const categories = [{ key: "all", text: "All Categories", value: "all" }];
    Object.values(categoriesById).forEach((c: Category) => {
      categories.push({
        key: c.name,
        text: c.name,
        value: c.name,
      });
    });
    return categories;
  };

  return (
    <>
      <Grid columns="3" padded="vertically" stackable>
        <Grid.Column width="5">
          {!onlyPagination && (
            <SearchBar
              onChange={onChange("search")}
              onSearch={onSearch}
              value={search}
              loading={loading}
            />
          )}
        </Grid.Column>
        <Grid.Column textAlign="center" width="6">
          <Pagination
            currentPage={page}
            pageCount={pageCount}
            onChange={onPageChange}
            loading={loading}
          />
        </Grid.Column>
        <Grid.Column textAlign="right" width="5">
          {!onlyPagination && (
            <>
              <Checkbox
                label={<Label>Main</Label>}
                onChange={() => onChange("isMain")(!isMain)}
                checked={isMain}
              />
              <Checkbox
                label={<Label>RTS</Label>}
                onChange={() => onChange("isInstock")(!isInstock)}
                checked={isInstock}
              />
              <Select
                name="category"
                options={categoryOptions()}
                onChange={(_, data) => onChange("category")(data.value)}
                value={category ? category : "all"}
              />
              <Select
                name="ordering"
                options={orderingOptions}
                onChange={(_, data) => onChange("ordering")(data.value)}
                value={ordering ? ordering : "-updated_at"}
              />
              <Select
                name="pageSize"
                options={pageSizeOptions}
                onChange={(_, data) => onChange("pageSize")(data.value)}
                value={pageSize ? pageSize : 20}
                compact
              />
            </>
          )}
        </Grid.Column>
      </Grid>
    </>
  );
};

export default ListingQuerySection;
