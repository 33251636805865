import React, { useState, useRef } from "react";
import { useDrag, useDrop, XYCoord } from "react-dnd";
import { Card, Image as SImage, DropdownProps } from "semantic-ui-react";
import ColorDropdown from "features/color/ColorDropdown";
import { ImageFormValues } from "api/productAPI";
import DeleteConfirmation from "components/DeleteConfirmation";

// add button on top of image
// https://www.w3schools.com/howto/howto_css_button_on_image.asp

// react-dnd tutorial
// https://medium.com/swlh/react-dnd-in-examples-ce509b25839d

interface DragItem {
  position: number;
  type: string;
}

interface ImageItemProps {
  index: number;
  image: ImageFormValues;
  colors: string[];
  onDelete: Function;
  onColorChange: Function;
  onReposition: (curr: number, target: number) => void;
  setCurrentDragIndex: (currentDragIndex: number | null) => void;
  currentDragIndex: number | null;
}

const ImageItem: React.FC<ImageItemProps> = ({
  index,
  image,
  colors,
  onDelete,
  onColorChange,
  onReposition,
  setCurrentDragIndex,
  currentDragIndex,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const [, drop] = useDrop({
    accept: "image",
    hover: (item: DragItem, monitor) => {
      console.log("drop hover");
      if (!ref.current) {
        return;
      }
      const currPosition = item.position;
      const targetPosition = index;
      if (currPosition === targetPosition) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientX = (clientOffset as XYCoord).x - hoverBoundingRect.left;
      // Dragging backwards (left)
      if (currPosition > targetPosition && hoverClientX > hoverMiddleX) {
        return;
      }
      // Dragging forwards (right)
      if (currPosition < targetPosition && hoverClientX < hoverMiddleX) {
        return;
      }
      onReposition(currPosition, targetPosition);
      setCurrentDragIndex(targetPosition);
      item.position = targetPosition;
    },
  });

  const [, drag] = useDrag({
    item: {
      position: index,
      type: "image",
    },
    begin: () => {
      console.log("drag begin");
      setCurrentDragIndex(index);
    },
    end: () => {
      console.log("drag end");
      setCurrentDragIndex(null);
    },
  });

  const opacity = currentDragIndex === index ? 0.3 : 1;

  const [isConfirmOpen, setIsConfirmOpen] = useState<boolean>(false);

  const handleChange = (
    _: React.SyntheticEvent<HTMLElement>,
    data: DropdownProps
  ) => {
    onColorChange(image.position, data.value);
  };

  const getImageURL = () => {
    if (typeof image.thumbnail === "string") {
      return image.thumbnail;
    }
    const url = URL.createObjectURL(image.thumbnail);
    return url;
  };

  drag(drop(ref));

  return (
    <div ref={ref} style={{ opacity }}>
      <Card style={{ width: 150 }}>
        <SImage src={getImageURL()} style={{ width: 150 }} ui={false} wrapped />
        <Card.Content>
          <ColorDropdown
            colors={colors}
            name="color"
            value={image.color}
            onChange={handleChange}
            compact
          />
          <DeleteConfirmation
            trigger={
              <div
                style={{ color: "red", cursor: "pointer" }}
                onClick={() => setIsConfirmOpen(true)}
              >
                delete
              </div>
            }
            open={isConfirmOpen}
            onClose={() => setIsConfirmOpen(false)}
            onConfirm={() => onDelete(image.id, image.position)}
          />
        </Card.Content>
      </Card>
    </div>
  );
};

export default ImageItem;
