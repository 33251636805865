import api from "./API";
import { TokenStorage } from "./tokenStorage";

const API_ENDPOINT = "token/obtain/staff/";

const login = async (email: string, password: string) => {
  const { data: jwt } = await api.post(API_ENDPOINT, {
    email,
    password,
  });
  TokenStorage.storeAccessToken(jwt.access);
  TokenStorage.storeRefreshToken(jwt.refresh);
  return TokenStorage.getCurrentUser();
};

const logout = () => {
  TokenStorage.clear();
  return null;
};

const getCurrentUser = () => {
  return TokenStorage.getCurrentUser();
};

export default {
  login,
  logout,
  getCurrentUser,
};
