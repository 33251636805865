import React, { useRef, useState } from "react";
import { QuaggaJSResultObject } from "@ericblade/quagga2";
import Scanner from "./ScannerTest";
import BarcodeScanner from "features/barcode/BarcodeScanner";

interface TestComponentProps {
  //
}

const TestComponent: React.FC<TestComponentProps> = () => {
  const [scanning, setScanning] = useState(false);
  const [results, setResults] = useState<QuaggaJSResultObject[]>([]);
  const scannerRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <button onClick={() => setScanning(!scanning)}>
        {scanning ? "stop" : "start"}
      </button>
      <ul className="results">
        {results.map(
          (result) =>
            result.codeResult && (
              <Result key={result.codeResult.code} result={result} />
            )
        )}
      </ul>
      <div
        ref={scannerRef}
        style={{ position: "relative", border: "3px solid red", width: "100%" }}
      >
        {/* <video style={{ width: window.innerWidth, height: 480, border: '3px solid orange' }}/> */}
        <canvas
          className="drawingBuffer"
          style={{
            position: "absolute",
            top: "0px",
            // left: "0px",
            // height: "100%",
            // width: "100%",
            border: "3px solid orange",
          }}
          width="100%"
          // width="640"
          // height="480"
        />
        {/* {scanning ? (
         *   <Scanner
         *     scannerRef={scannerRef}
         *     onDetected={(result) => setResults([...results, result])}
         *   />
         * ) : null} */}
        {scanning ? (
          <BarcodeScanner
            scannerRef={scannerRef}
            onDetected={(result: QuaggaJSResultObject) =>
              setResults([...results, result])
            }
          />
        ) : null}
      </div>
    </>
  );
};

const Result = ({ result }: { result: QuaggaJSResultObject }) => (
  <li>
    {result.codeResult.code} [{result.codeResult.format}]
  </li>
);

export default TestComponent;
