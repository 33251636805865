import React, { useEffect, useState } from "react";
import OrderFormSearchSection from "./OrderFormSearchSection";

interface OrderFormProps {}

const OrderForm: React.FC<OrderFormProps> = ({}) => {
  return (
    <>
      <OrderFormSearchSection />
    </>
  );
};

export default OrderForm;
