import React from "react";
import { Route, Redirect } from "react-router-dom";
import { Segment } from "semantic-ui-react";

const ProtectedRoute = ({ component: Component, user, ...rest }) => {
  const { userId: isAuthenticated } = user;
  // const { userId, role } = user;
  // const isAuthenticated = userId && (role === "admin" || role === "staff");
  return (
    <Route
      {...rest}
      render={(routerProps) =>
        isAuthenticated ? (
          <Segment raised padded>
            <Component {...routerProps} />
          </Segment>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

// TODO
// - change to ts.
// - redirect to login with location. (https://reactrouter.com/web/example/auth-workflow)

export default ProtectedRoute;
