import React from "react";
import { Header } from "semantic-ui-react";
import { useAppDispatch } from "app/store";
import { useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import Search from "components/Search";
import { fetchProducts, resetProducts } from "features/product/productSlice";

interface ReferenceSectionProps {
  onReferenceSelect: Function;
}

const ReferenceSection: React.FC<ReferenceSectionProps> = ({
  onReferenceSelect,
}) => {
  const { entities: productsById, loading } = useSelector(
    (state: RootState) => state.products
  );
  const dispatch = useAppDispatch();

  const handleSearch = (searchQuery: string) => {
    dispatch(fetchProducts({ search: searchQuery, page: 1, pageSize: 20 }));
  };

  const raiseReferenceSelect = (id: number) => {
    dispatch(resetProducts());
    onReferenceSelect(id, true);
  };

  const options = Object.values(productsById).map((p) => {
    return { key: p.id, text: p.styleNumber, value: p.id };
  });

  return (
    <>
      <Header size="medium">Product Reference</Header>
      <Search
        onSelect={raiseReferenceSelect}
        onSearch={handleSearch}
        options={options}
        loading={loading}
      />
    </>
  );
};

export default ReferenceSection;
