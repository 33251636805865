import React, { useState } from "react";
import styled from "styled-components";
import { Form, Button, InputProps } from "semantic-ui-react";

const Option = styled.div`
  cursor: pointer;
  position: relative;
  display: block;
  border: none;
  height: auto;
  padding: 0.5rem 1rem;
  pointer-events: all;
  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
  z-index: 13;
`;

const Options = styled.div<{ visible: boolean }>`
  display: ${(props) => (props.visible ? "block;" : "none;")}
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 15rem;
  width: calc(100% + 2px);
  position: absolute;
  top: 100%;
  border-top-width: 0 !important;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-color: #96c8da;
  border-radius: 0 0 0.28571429rem 0.28571429rem;
  background: #fff;
  z-index: 11;
`;

interface SearchProps {
  options: {
    key: number;
    value: number;
    text: string;
  }[];
  loading?: boolean;
  onSelect: Function;
  onSearch: Function;
  placeholder?: string;
}

const Search: React.FC<SearchProps> = ({
  options,
  onSelect,
  onSearch,
  placeholder,
  loading,
}) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [focus, setFocus] = useState(false);

  const raiseSearch = () => {
    onSearch(searchQuery);
  };

  const raiseSelect = (value: number, text: string) => {
    setSearchQuery(text);
    setFocus(false);
    onSelect(value);
  };

  const handleChange = (
    _: React.SyntheticEvent<HTMLInputElement>,
    data: InputProps
  ) => {
    setSearchQuery(data.value.toUpperCase());
  };

  return (
    <>
      <Form onSubmit={raiseSearch} style={{ maxWidth: "400px" }}>
        <Form.Input
          placeholder={
            placeholder ? placeholder : "Search style number of product."
          }
          value={searchQuery}
          onChange={handleChange}
          onFocus={() => setFocus(true)}
          loading={loading}
          action
        >
          <input />
          <Button type="button" icon="remove" onClick={() => setFocus(false)} />
          <Button type="submit" icon="search" loading={loading} primary />
        </Form.Input>
        <Options visible={focus && options.length > 0}>
          {options.map((opt) => (
            <Option
              key={opt.key}
              onClick={() => raiseSelect(opt.value, opt.text)}
            >
              {opt.text}
            </Option>
          ))}
        </Options>
      </Form>
    </>
  );
};

export default Search;
