import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Loader } from "semantic-ui-react";
import { RootState } from "app/rootReducer";
import { useAppDispatch } from "app/store";
import { Color } from "api/types";
import PageHeader from "components/PageHeader";
import SearchBar from "components/SearchBar";
import ColorForm from "./ColorForm";
import ColorList from "./ColorList";
import { fetchColors } from "./colorSlice";

const ColorPage: React.FC = () => {
  const { entities: colorsById, loading } = useSelector(
    (state: RootState) => state.colors
  );
  const [colors, setColors] = useState<Color[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const dispatch = useAppDispatch();

  const getSortedColors = () => {
    let filtered = Object.values(colorsById);
    if (searchQuery) {
      filtered = Object.values(colorsById).filter((c: Color) =>
        c.name.toUpperCase().startsWith(searchQuery.toUpperCase())
      );
    }
    const sorted = _.orderBy(filtered, "name", "asc");
    return sorted;
  };

  const handleSearch = () => {
    setColors(getSortedColors());
  };

  useEffect(() => {
    dispatch(fetchColors());
  }, [dispatch]);

  useEffect(() => {
    setColors(_.orderBy(Object.values(colorsById), "name", "asc"));
  }, [colorsById]);

  return (
    <>
      <PageHeader
        header="Colors"
        subheader="Manage Colors"
        icon="paint brush"
      />
      <ColorForm color={null} />
      <SearchBar
        onChange={setSearchQuery}
        onSearch={handleSearch}
        value={searchQuery}
        loading={loading}
      />
      {loading ? (
        <div style={{ margin: "3rem 0rem" }}>
          <Loader active inline="centered" />
        </div>
      ) : (
        <ColorList colors={colors} />
      )}
    </>
  );
};

export default ColorPage;
