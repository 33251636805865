import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Switch, Redirect } from "react-router-dom";
import "./App.css";

// components
import { RootState } from "./rootReducer";
import NavBar from "components/NavBar";
import ProtectedRoute from "components/ProtectedRoute";
import ProductDetailPage from "features/product/productDetail/ProductDetailPage";
import ProductListPage from "features/product/productList/ProductListPage";
import ListingListPage from "features/listing/listingList/ListingListPage";
import ListingDetailPage from "features/listing/listingDetail/ListingDetailPage";
import LinesheetPage from "features/linesheet/LinesheetPage";
import OrderDetailPage from "features/order/orderDetail/OrderDetailPage";
// import QRCodePage from "features/qrcode/QRCodePage";
import BarcodePage from "features/barcode/BarcodePage";
import ColorPage from "features/color/ColorPage";
import PackPage from "features/pack/PackPage";
import CategoryPage from "features/category/CategoryPage";
import LogInForm from "features/auth/LogInForm";
import AuthRoute from "components/AuthRoute";
import LogOutPage from "features/auth/LogOut";
import Home from "components/Home";
import TestComponent from "components/TestComponent";
import { useAppDispatch } from "./store";
import { fetchColors } from "features/color/colorSlice";
import { fetchPacks } from "features/pack/packSlice";
import { fetchCategories } from "features/category/categorySlice";

const App: React.FC = () => {
  const { user } = useSelector((state: RootState) => state.auth);

  console.log("env", process.env.NODE_ENV);

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchColors());
    dispatch(fetchPacks());
    dispatch(fetchCategories());
    // TODO reset above.
  }, [dispatch]);

  return (
    <div className="App">
      <BrowserRouter>
        <NavBar user={user} />
        <main>
          <Switch>
            <ProtectedRoute
              user={user}
              path="/products/:id"
              component={ProductDetailPage}
            />
            <ProtectedRoute
              user={user}
              path="/products"
              component={ProductListPage}
            />
            <ProtectedRoute
              user={user}
              path="/listings/:id"
              component={ListingDetailPage}
            />
            <ProtectedRoute
              user={user}
              path="/listings"
              component={ListingListPage}
            />
            <ProtectedRoute
              user={user}
              path="/orders/:id"
              component={OrderDetailPage}
            />
            <ProtectedRoute
              user={user}
              exact
              path="/linesheets"
              component={LinesheetPage}
            />
            <ProtectedRoute
              user={user}
              exact
              path="/barcodes"
              component={BarcodePage}
            />
            {/* <ProtectedRoute
             *   user={user}
             *   exact
             *   path="/qrcodes"
             *   component={QRCodePage}
             * /> */}
            <ProtectedRoute
              user={user}
              exact
              path="/colors"
              component={ColorPage}
            />
            <ProtectedRoute
              user={user}
              exact
              path="/packs"
              component={PackPage}
            />
            <ProtectedRoute
              user={user}
              exact
              path="/categories"
              component={CategoryPage}
            />
            <ProtectedRoute
              user={user}
              exact
              path="/test"
              component={TestComponent}
            />
            <AuthRoute user={user} exact path="/login" component={LogInForm} />
            <ProtectedRoute
              user={user}
              exact
              path="/logout"
              component={LogOutPage}
            />
            <ProtectedRoute user={user} exact path="/" component={Home} />
            <Redirect to="/" />
          </Switch>
        </main>
      </BrowserRouter>
    </div>
  );
};

export default App;
