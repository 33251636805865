import React, { useEffect, useState } from "react";
import { Button, DropdownProps, Form, Message, Modal } from "semantic-ui-react";
import { Pack, Size } from "api/types";
import { useAppDispatch } from "app/store";
import { createPack, updatePack } from "./packSlice";

interface PackFormProps {
  pack: Pack | null;
}

const sizeOptions = [
  { key: "REGULAR", text: "Regular", value: "REGULAR" },
  { key: "PLUS", text: "Plus", value: "PLUS" },
  { key: "ONE SIZE", text: "One Size", value: "ONE_SIZE" },
];

const PackForm = ({ pack }: PackFormProps) => {
  const dispatch = useAppDispatch();

  const [size, setSize] = useState<Size | undefined>(undefined);
  const [name, setName] = useState<string>("");
  const [breakdown, setBreakdown] = useState<string>("");
  const [qty, setQty] = useState<number>(0);
  const [error, setError] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (pack) {
      setSize(pack.size);
      setName(pack.name);
      setBreakdown(pack.breakdown);
    }
  }, [pack]);

  useEffect(() => {
    const qty = breakdown.split("-").reduce((a, b) => Number(a) + Number(b), 0);
    setQty(qty);
  }, [breakdown]);

  const close = () => {
    // revert changes to initial pack value on closing
    pack ? setName(pack.name) : setName("");
    pack ? setBreakdown(pack.breakdown) : setBreakdown("");
    setError("");
    setIsOpen(false);
  };
  const open = () => {
    setIsOpen(true);
  };

  const validateData = () => {
    const nameRegex = /^[0-9a-zA-Z]+(-[0-9a-zA-Z]+)*$/;
    const breakdownRegex = /^\d+(-\d+)*$/;
    if (nameRegex.test(name) && breakdownRegex.test(breakdown)) {
      return name.split("-").length === breakdown.split("-").length;
    }
    return false;
  };

  const handleSubmit = () => {
    if (size === undefined) {
      setError("Size is required");
      return;
    }
    if (!validateData()) {
      setError(
        "Name and breakdown must be in equal length. (i.e. S-M-L = 2-2-2 = length 3)"
      );
      return;
    }
    if (pack) {
      dispatch(updatePack({ id: pack.id, size, name, breakdown }));
    } else {
      dispatch(
        createPack({ size: size as Size, name, breakdown, totalQty: qty })
      );
    }
    close();
  };

  const handleSizeChange = (
    e: React.SyntheticEvent<HTMLElement>,
    data: DropdownProps
  ) => {
    setSize(data.value as Size);
  };

  const handleBreakdownChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const re = /^\d+(-\d+)*(-?)$/;
    const value = e.currentTarget.value;
    if (value === "" || re.test(value)) {
      setBreakdown(value.toUpperCase());
    }
  };

  const handleNameChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const re = /^[0-9a-zA-Z]+(-[0-9a-zA-Z]+)*(-?)$/;
    const value = e.currentTarget.value;
    if (value === "" || re.test(value)) {
      setName(value.toUpperCase());
    }
  };

  const getTrigger = () => {
    return pack ? (
      <span
        style={{ color: "blue", fontSize: "0.9rem", cursor: "pointer" }}
        onClick={open}
      >
        Edit
      </span>
    ) : (
      <Button content="Create New Pack" onClick={open} primary />
    );
  };

  return (
    <Modal
      as={Form}
      open={isOpen}
      onClose={close}
      size="tiny"
      closeOnDimmerClick={false}
      trigger={getTrigger()}
      onSubmit={handleSubmit}
    >
      <Modal.Header content={pack ? "Edit Pack" : "Create New Pack"} />
      <Modal.Content>
        {error && <Message negative content={error} />}
        <Form.Select
          label="Size"
          name="size"
          type="text"
          value={size}
          options={sizeOptions}
          onChange={handleSizeChange}
          placeholder="Select a size this pack belongs to"
          error={error ? true : false}
          required
        />
        <Form.Input
          label="Pack name"
          name="name"
          type="text"
          value={name}
          onChange={handleNameChange}
          placeholder="e.g. 'S-M-L' or 'One Size'"
          error={error ? true : false}
          required
        />
        <Form.Input
          label="Pack breakdown"
          name="breakdown"
          type="text"
          value={breakdown}
          onChange={handleBreakdownChange}
          placeholder="e.g. '2-2-2' or '6'"
          error={error ? true : false}
          required
        />
        <Form.Input label="Pack qty in pieces." value={qty} readOnly />
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" content="Cancel" onClick={close} />
        <Button type="submit" content="Submit" primary />
      </Modal.Actions>
    </Modal>
  );
};

export default PackForm;
