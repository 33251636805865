import React, { useState } from "react";
import { useAppDispatch } from "app/store";
import { Color } from "api/types";
import { List, Modal, Button } from "semantic-ui-react";
import ColorForm from "./ColorForm";
import { deleteColor } from "./colorSlice";

interface ColorListItemProps {
  color: Color;
}

const ColorListItem: React.FC<ColorListItemProps> = ({ color }) => {
  const dispatch = useAppDispatch();
  const [isConfirmOpen, setIsConfirmOpen] = useState<boolean>(false);

  const openConfirm = () => {
    setIsConfirmOpen(true);
  };
  const closeConfirm = () => {
    setIsConfirmOpen(false);
  };
  const onDeleteConfirm = () => {
    const result = dispatch(deleteColor(color.id));
    console.log("deleteColor result:", result);
  };

  const renderDeleteConfirm = () => {
    const trigger = (
      <span
        style={{ color: "red", fontSize: "0.9rem", cursor: "pointer" }}
        onClick={openConfirm}
      >
        Delete
      </span>
    );
    return (
      <Modal size="mini" open={isConfirmOpen} trigger={trigger}>
        <Modal.Header>Are you sure to delete?</Modal.Header>
        <Modal.Content>
          Color to be deleted: <strong>{color.name}</strong>
        </Modal.Content>
        <Modal.Actions>
          <Button negative content="Cancel" onClick={closeConfirm} />
          <Button positive content="Confirm" onClick={onDeleteConfirm} />
        </Modal.Actions>
      </Modal>
    );
  };

  return (
    <List.Item
      className="color-item"
      style={{
        width: "160px",
        margin: "1rem 0rem",
        padding: "0px 5px",
        verticalAlign: "top",
      }}
    >
      <div className="color-name">
        <strong>{color.name}</strong>
      </div>
      <div>
        <ColorForm color={color} />
        {" | "}
        {renderDeleteConfirm()}
      </div>
    </List.Item>
  );
};

export default ColorListItem;
