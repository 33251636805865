import React from "react";
import _ from "lodash";
import styled from "styled-components";
import { Listing } from "api/types";

const Page = styled.div`
  page-break-inside: avoid;
  page-break-after: always;
  vertical-align: middle;
  width: 1100px;
  padding: 50px 40px;
`;

const Header = styled.div`
  padding-bottom: 40px;
`;
const Logo = styled.img`
  display: inline-block;
  width: 200px;
`;

const Info = styled.div`
  display: inline-block;
  float: right;
  text-align: right;
  height: 100px;
`;
const StyleNumber = styled.div`
  height: 45px;
  margin-top: 8px;
  font-size: 35pt;
`;

const Price = styled.div`
  height: 40px;
  font-size: 18pt;
`;

const Image = styled.img`
  width: 340px;
`;

const ColorList = styled.ul`
  padding: 0 20px;
`;

const ColorItem = styled.li`
  width: 240px;
  display: inline-block;
`;

interface LinesheetPrintProps {
  listings: Listing[];
}

class LinesheetPrint extends React.Component<LinesheetPrintProps, {}> {
  renderPrice = (listing: Listing) => {
    if (listing.isOnesize) {
      return <Price>${(listing.onesizePrice / 100).toFixed(2)}</Price>;
    }
    return (
      <Price>
        ${(listing.regularPrice / 100).toFixed(2)} / $
        {(listing.plusPrice / 100).toFixed(2)}
      </Price>
    );
  };
  renderImages = (listing: Listing) => {
    const idx = Math.min(listing.images.length, 3);
    const images = [];
    for (let i = 0; i < idx; i++) {
      images.push(
        <Image
          key={listing.images[i].id}
          src={listing.images[i].image as string}
          alt="Image"
        />
      );
    }
    return <div>{images}</div>;
  };
  renderColors = (listing: Listing) => {
    const colors = _.uniq(listing.variations.map((v) => v.color));
    return colors.map((c) => <ColorItem key={c}>&#9744; {c}</ColorItem>);
  };

  render() {
    return (
      <div id="linesheet-print">
        {this.props.listings.map((listing) => {
          return (
            <Page key={listing.id}>
              <Header>
                <span>
                  <Logo
                    src="https://heimishusa.s3-us-west-2.amazonaws.com/public/static/logo_heimishusa_md.png"
                    alt="Logo"
                  />
                </span>
                <Info>
                  <StyleNumber>{listing.styleNumber}</StyleNumber>
                  {this.renderPrice(listing)}
                </Info>
              </Header>
              <div>{this.renderImages(listing)}</div>
              <ColorList>{this.renderColors(listing)}</ColorList>
            </Page>
          );
        })}
      </div>
    );
  }
}

export default LinesheetPrint;
