import React, { useState } from "react";
import { ProductVariationFormValues } from "api/productAPI";
import { Table, Button, Confirm } from "semantic-ui-react";
import { Size } from "api/types";

interface VariationTableProps {
  variations: ProductVariationFormValues[];
  onRemoval: (id: number | undefined, color: string, size: Size) => void;
}

const VariationTable: React.FC<VariationTableProps> = ({
  variations,
  onRemoval,
}) => {
  const [confirmOpenIndex, setConfirmOpenIndex] = useState<number | undefined>(
    undefined
  );

  const handleRemoval = (id: number | undefined, color: string, size: Size) => {
    setConfirmOpenIndex(undefined);
    onRemoval(id, color, size);
  };
  const renderVariations = () => {
    if (variations === undefined || variations.length === 0) {
      return (
        <Table.Row>
          <Table.Cell rowSpan="5">No variations yet.</Table.Cell>
        </Table.Row>
      );
    }
    return variations.map((v: ProductVariationFormValues, index) => {
      return (
        <Table.Row key={v.color + v.size}>
          <Table.Cell width="3">{v.color}</Table.Cell>
          <Table.Cell width="3">{v.size}</Table.Cell>
          <Table.Cell width="3">{v.pack}</Table.Cell>
          <Table.Cell width="3">{v.packBreakdown}</Table.Cell>
          <Table.Cell width="1" textAlign="center">
            <Button
              icon="remove"
              size="tiny"
              negative
              compact
              onClick={() => setConfirmOpenIndex(index)}
            />
            <Confirm
              header="Are you sure?"
              content="This action cannot be undone."
              open={confirmOpenIndex === index}
              size="mini"
              onCancel={() => setConfirmOpenIndex(undefined)}
              onConfirm={() => handleRemoval(v.id, v.color, v.size)}
              confirmButton={<Button content="Delete" negative />}
            />
            {/* <DeleteConfirmation
             *   trigger={
             *     <Button
             *       icon="remove"
             *       color="red"
             *       size="tiny"
             *       compact
             *       onClick={() => setIsConfirmOpen(true)}
             *     />
             *   }
             *   open={isConfirmOpen}
             *   onClose={() => setIsConfirmOpen(false)}
             *   onConfirm={() => onRemoval(v.id, v.color, v.size)}
             * /> */}
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  return (
    <Table celled padded compact striped>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Color</Table.HeaderCell>
          <Table.HeaderCell>Size</Table.HeaderCell>
          <Table.HeaderCell>Pack</Table.HeaderCell>
          <Table.HeaderCell>Pack Breakdown</Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>{renderVariations()}</Table.Body>
    </Table>
  );
};

export default VariationTable;
