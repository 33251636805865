import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { RootState } from "app/rootReducer";
import { useAppDispatch } from "app/store";
import { Category } from "api/types";
import PageHeader from "components/PageHeader";
import SearchBar from "components/SearchBar";
import CategoryForm from "./CategoryForm";
import CategoryList from "./CategoryList";
import { fetchCategories } from "./categorySlice";

const CategoryPage: React.FC = () => {
  const { entities: categoriesById, loading /*error*/ } = useSelector(
    (states: RootState) => states.categories
  );
  const [categories, setCategories] = useState<Category[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const dispatch = useAppDispatch();

  const getSortedCategories = () => {
    let filtered = Object.values(categoriesById);
    if (searchQuery) {
      filtered = Object.values(categoriesById).filter((p: Category) =>
        p.name.startsWith(searchQuery)
      );
    }
    const sorted = _.orderBy(filtered, "name", "asc");
    return sorted;
  };

  const handleSearch = () => {
    setCategories(getSortedCategories());
  };

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  useEffect(() => {
    setCategories(_.orderBy(Object.values(categoriesById), "name", "asc"));
  }, [categoriesById]);

  return (
    <>
      <PageHeader
        header="Categories"
        subheader="Manage Categories"
        icon="settings"
      />
      <CategoryForm category={null} />
      <SearchBar
        onChange={setSearchQuery}
        onSearch={handleSearch}
        value={searchQuery}
        loading={loading}
      />
      <CategoryList categories={categories} />
    </>
  );
};

export default CategoryPage;
