import React from "react";
import { Listing } from "api/types";
import { Card, Image } from "semantic-ui-react";
import { NavLink } from "react-router-dom";

interface ListingListItemProps {
  listing: Listing;
}

const ListingListItem: React.FC<ListingListItemProps> = ({ listing }) => {
  const {
    id,
    styleNumber,
    name,
    isOnesize,
    regularPrice,
    plusPrice,
    onesizePrice,
    thumbnail,
  } = listing;

  const renderPrice = () => {
    if (isOnesize) {
      return (
        <div>
          <strong>OneSize: ${(onesizePrice / 100).toFixed(2)}</strong>
        </div>
      );
    }
    return (
      <>
        <div>
          <strong>Reg: $</strong>
          {(regularPrice / 100).toFixed(2)}
        </div>
        <div>
          <strong>Plus: $</strong>
          {(plusPrice / 100).toFixed(2)}
        </div>
      </>
    );
  };

  return (
    <Card style={{ width: "140px" }}>
      <Image size="small" centered src={thumbnail} />
      <Card.Content>
        <Card.Header as={NavLink} to={`/listings/${id}`}>
          {styleNumber}
        </Card.Header>
        <Card.Meta
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {name}
        </Card.Meta>
        <Card.Description>{renderPrice()}</Card.Description>
      </Card.Content>
    </Card>
  );
};

export default ListingListItem;
