import api from "./API";
import { QueryParams, PageSize, Size, Pack, Category, Image } from "./types";

const API_ENDPOINT = "v1/listings/";

export type listingQueryParams = QueryParams & {
  category?: string;
  isActive?: boolean;
  isInstock?: boolean;
  isMain?: boolean;
  ordering?: "updated_at" | "-updated_at" | "style_number" | "-style_number";
  pageSize?: PageSize;
};

const getListings = async (query: listingQueryParams) => {
  const response = await api.get(API_ENDPOINT, { params: query });
  return response.data;
};

const getListingById = async (id: number) => {
  const endpoint = API_ENDPOINT + id + "/";
  const response = await api.get(endpoint);
  return response.data;
};

const createListing = async (data: ListingFormData) => {
  const listingData = formatFormData(data);
  const response = await api.post(API_ENDPOINT, listingData);
  return response.data;
};

const updateListing = async (id: number, data: ListingFormData) => {
  const endpoint = API_ENDPOINT + id + "/";
  const listingData = formatFormData(data);
  const response = await api.put(endpoint, listingData);
  return response.data;
};

const deleteListing = async (id: number) => {
  const endpoint = API_ENDPOINT + id + "/";
  const response = await api.delete(endpoint);
  return response.data;
};

const removeVariations = async (id: number, data: number[]) => {
  const endpoint = API_ENDPOINT + id + "/remove_variations/";
  const response = await api.post(endpoint, data);
  return response.data;
};

export default {
  getListings,
  getListingById,
  createListing,
  updateListing,
  deleteListing,
  removeVariations,
};

const formatFormData = (formData: ListingFormData) => {
  const {
    category,
    regularPack,
    plusPack,
    onesizePack,
    regularPrice,
    plusPrice,
    onesizePrice,
    ...rest
  } = formData;
  return {
    ...rest,
    category: category ? category.id : null,
    regularPack: regularPack ? regularPack.id : null,
    plusPack: plusPack ? plusPack.id : null,
    onesizePack: onesizePack ? onesizePack.id : null,
    regularPrice: Math.round(Number(regularPrice * 100)),
    plusPrice: Math.round(Number(plusPrice * 100)),
    onesizePrice: Math.round(Number(onesizePrice * 100)),
  };
};

// const constructQueryString = ({
//   search,
//   lookup,
//   category,
//   isActive,
//   isInstock,
//   isMain,
//   page,
//   pageSize,
// }: listingQueryParams): string => {
//   const queryString = [];
//   search && queryString.push(`search=${search}`);
//   lookup && queryString.push(`lookup=${lookup}`);
//   category && queryString.push(`category=${category}`);
//   isActive && queryString.push(`is_active=${isActive ? "True" : "False"}`);
//   isInstock && queryString.push(`is_instock=${isInstock ? "True" : "False"}`);
//   isMain && queryString.push(`is_main=${isMain ? "True" : "False"}`);
//   queryString.push(`page=${page}`);
//   queryString.push(`page_size=${pageSize}`);
//   return queryString.length > 0 ? "?" + queryString.join("&") : "";
// };

export interface ListingVariationFormData {
  id?: number;
  size: Size;
  color: string;
  pack: string;
  packBreakdown: string;
  qtyPerPack?: number;
  qty: number;
}

export interface ListingFormData {
  id?: number;
  product: number | null;
  styleNumber: string;
  name: string;
  description: string;
  category: Category | null;
  isMain: boolean;
  isActive: boolean;
  isInstock: boolean;
  isOnesize: boolean;
  regularPrice: number;
  plusPrice: number;
  onesizePrice: number;
  regularPack: Pack | null;
  plusPack: Pack | null;
  onesizePack: Pack | null;
  images: Image[];
  variations: ListingVariationFormData[];
}
