import React, { useState, useEffect } from "react";
import { Form, Modal, Button } from "semantic-ui-react";
import { useAppDispatch } from "app/store";
import { Category } from "api/types";
import { updateCategory, createCategory } from "./categorySlice";

interface CategoryFormProps {
  category: Category | null;
}

const CategoryForm = ({ category }: CategoryFormProps) => {
  const dispatch = useAppDispatch();
  const [name, setName] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (category) {
      setName(category.name);
    }
  }, [category]);

  const close = () => {
    // revert changes to initial category value on closing
    category ? setName(category.name) : setName("");
    setIsOpen(false);
  };
  const open = () => {
    setIsOpen(true);
  };

  const handleSubmit = () => {
    if (category) {
      dispatch(updateCategory({ id: category.id, name }));
    } else {
      dispatch(createCategory({ name }));
    }
    close();
  };

  const getTrigger = () => {
    return category ? (
      <span
        style={{ color: "blue", fontSize: "0.9rem", cursor: "pointer" }}
        onClick={open}
      >
        Edit
      </span>
    ) : (
      <Button content="Create New Category" onClick={open} primary />
    );
  };

  return (
    <Modal
      as={Form}
      open={isOpen}
      onClose={close}
      size="tiny"
      closeOnDimmerClick={false}
      trigger={getTrigger()}
      onSubmit={handleSubmit}
    >
      <Modal.Header
        content={category ? "Edit Category" : "Create New Category"}
      />
      <Modal.Content style={{ fontSize: "1rem" }}>
        <Form.Input
          label="Category name"
          type="text"
          value={name}
          onChange={(e) => setName(e.currentTarget.value.toUpperCase())}
          placeholder="e.g. Top, Dress, Pants, etc."
        />
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" content="Cancel" onClick={close} />
        <Button type="submit" content="Submit" primary />
      </Modal.Actions>
    </Modal>
  );
};

export default CategoryForm;
