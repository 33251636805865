import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Search,
  SearchProps,
  SearchResultData,
  DropdownItemProps,
  Form,
} from "semantic-ui-react";
import { RootState } from "app/rootReducer";
import { Color } from "api/types";

interface ColorSearchProps {
  label: string;
  name: string;
  value: Color | null;
  onSearch: Function;
}

const ColorSearch: React.FC<ColorSearchProps> = ({ value, onSearch }) => {
  const { entities: colorsById, loading } = useSelector(
    (state: RootState) => state.colors
  );

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchResults, setSearchResults] = useState<DropdownItemProps>([]);

  const colors = Object.values(colorsById);

  const handleSearchChange = (
    _: React.SyntheticEvent<HTMLElement, Event>,
    data: SearchProps
  ) => {
    if (!data.value || data.value == null) {
      setSearchQuery("");
      setSearchResults([]);
      return;
    }
    const newSearchQuery = data.value.toUpperCase();
    setSearchQuery(newSearchQuery);
    onSearch(null);
    if (data.value.length < 3) {
      setSearchResults([]);
      return;
    }
    const newSearchResults = colors
      .filter((c: Color) => c.name.startsWith(newSearchQuery))
      .map((c: Color) => {
        return {
          id: c.id,
          title: c.name,
        };
      });
    setSearchResults(newSearchResults);
  };

  const raiseResult = (_: React.SyntheticEvent, data: SearchResultData) => {
    console.log("result selected");
    const resultColor = colorsById[data.result.id];
    setSearchQuery(resultColor.name);
    onSearch(resultColor);
  };

  const blurHandler = (_: React.SyntheticEvent, data: SearchProps) => {
    // select first result
    if (!value && data.results && data.results.length > 0) {
      const { id } = (data.results as Array<{
        id: number;
      }>)[0];
      const resultColor = colorsById[id];
      setSearchQuery(resultColor.name);
      onSearch(resultColor);
    }
  };

  return (
    <>
      <Form.Field>
        <Search
          loading={loading}
          onResultSelect={raiseResult}
          onSearchChange={handleSearchChange}
          onBlur={blurHandler}
          results={searchResults}
          value={searchQuery}
          placeholder="Search & Select color"
        />
      </Form.Field>
    </>
  );
};

export default ColorSearch;
