import React from "react";
import { List } from "semantic-ui-react";
import { Category } from "api/types";
import CategoryListItem from "./CategoryListItem";

interface CategoryListProps {
  categories: Category[];
}

const CategoryList: React.FC<CategoryListProps> = ({ categories }) => {
  const categoryItems = categories.map((category) => (
    <CategoryListItem key={category.id} category={category} />
  ));

  return <List horizontal>{categoryItems}</List>;
};

export default CategoryList;
