import api from "./API";
import { Category } from "./types";

const API_ENDPOINT = "v1/categories/";

const fetch = async () => {
  const response = await api.get(API_ENDPOINT);
  return response.data;
};

const create = async (data: Omit<Category, "id">) => {
  const response = await api.post(API_ENDPOINT, data);
  return response.data;
};

const updateById = async (id: number, data: Partial<Category>) => {
  const response = await api.put(API_ENDPOINT + `${id}/`, data);
  return response.data;
};

const deleteById = async (id: number) => {
  const response = await api.delete(API_ENDPOINT + `${id}/`);
  return response.data;
};

export default {
  fetch,
  create,
  updateById,
  deleteById,
};
