import React, { useEffect, useState } from "react";
import { ListingFormData, ListingVariationFormData } from "api/listingAPI";
import { Header, Form } from "semantic-ui-react";
import ColorSearch from "features/color/ColorSearch";
import { Size, Color } from "api/types";
import SizeDropdown from "features/size/SizeDropdown";
import VariationTable from "features/listing/listingDetail/VariationsTable";

interface ListingFormVariationsSectionProps {
  item: ListingFormData;
  onVariationQtyChange: Function;
  onVariationAdd: Function;
  onVariationRemoval: (
    id: number | undefined,
    color: string,
    size: Size
  ) => void;
}

const ListingFormVariationsSection: React.FC<ListingFormVariationsSectionProps> = ({
  item,
  onVariationQtyChange,
  onVariationAdd,
  onVariationRemoval,
}) => {
  const [color, setColor] = useState<Color | null>(null);
  const [size, setSize] = useState<Size>();
  const [variations, setVariations] = useState<ListingVariationFormData[]>([]);

  useEffect(() => {
    setVariations(item.variations);
  }, [item]);

  const getPack = () => {
    if (size === "REGULAR") {
      return item.regularPack;
    } else if (size === "PLUS") {
      return item.plusPack;
    }
    return item.onesizePack;
  };

  const handleAddition = () => {
    const pack = getPack();
    if (pack && color && size) {
      onVariationAdd({
        color: color.name,
        size,
        pack: pack.name,
        packBreakdown: pack.breakdown,
        qty: 0,
      });
    }
  };

  return (
    <>
      <Header size="medium">Color / Size Variations</Header>
      <Form.Group>
        <ColorSearch
          label="Color"
          name="color"
          onSearch={setColor}
          value={color}
        />
        <SizeDropdown
          label="Size"
          name="size"
          onSelect={setSize}
          value={size}
          isOnesize={item.isOnesize}
        />
        <Form.Button
          type="submit"
          content="Add"
          onClick={handleAddition}
          color="twitter"
        />
      </Form.Group>
      <VariationTable
        variations={variations}
        onRemoval={onVariationRemoval}
        onQtyChange={onVariationQtyChange}
      />
    </>
  );
};

export default ListingFormVariationsSection;
