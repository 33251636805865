import React from "react";
import { Header, Form, Grid } from "semantic-ui-react";
import { ProductFormValues } from "api/productAPI";
import CategoryDropdown from "features/category/CategoryDropdown";
import PackDropdown from "features/pack/PackDropdown";

interface ItemInfoFormSectionProps {
  item: ProductFormValues;
  onChange: Function;
}

const ItemInfoFormSection: React.FC<ItemInfoFormSectionProps> = ({
  item,
  onChange,
}) => {
  const pricePackRenderer = () => {
    if (item.isOnesize) {
      return (
        <Form.Group>
          <Form.Input
            label="OneSize Price"
            name="onesizePrice"
            value={item.onesizePrice > 0 ? item.onesizePrice : ""}
            onChange={onChange("onesizePrice")}
          />
          <PackDropdown
            label="OneSize Pack"
            name="onesizePack"
            size="ONE_SIZE"
            value={item.onesizePack ? item.onesizePack.id : undefined}
            onChange={onChange("onesizePack")}
          />
        </Form.Group>
      );
    }
    return (
      <>
        <Form.Group>
          <Form.Input
            label="Regular Price"
            name="regularPrice"
            value={item.regularPrice > 0 ? item.regularPrice : ""}
            onChange={onChange("regularPrice")}
          />
          <PackDropdown
            label="Regular Pack"
            name="regularPack"
            size="REGULAR"
            value={item.regularPack ? item.regularPack.id : undefined}
            onChange={onChange("regularPack")}
          />
        </Form.Group>
        <Form.Group>
          <Form.Input
            label="Plus Price"
            name="plusPrice"
            value={item.plusPrice > 0 ? item.plusPrice : ""}
            onChange={onChange("plusPrice")}
          />
          <PackDropdown
            label="Plus Pack"
            name="plusPack"
            size="PLUS"
            value={item.plusPack ? item.plusPack.id : undefined}
            onChange={onChange("plusPack")}
          />
        </Form.Group>
      </>
    );
  };

  return (
    <>
      <Header size="medium">Item Information</Header>
      <Grid columns="equal" stackable>
        <Grid.Row>
          <Grid.Column>
            <Form.Input
              label="Style Number"
              name="styleNumber"
              type="text"
              placeholder="i.e. ST1234-5 (max 20 characters)"
              onChange={onChange("styleNumber")}
              value={item.styleNumber}
              // width={4}
            />
            <Form.Input
              label="Name"
              name="name"
              type="text"
              placeholder="i.e. Floral Sleeveless Top (max 80 characters)"
              onChange={onChange("name")}
              value={item.name}
              // width={5}
            />
            <Form.TextArea
              label="Description"
              name="description"
              onChange={onChange("description")}
              value={item.description}
              rows={6}
            />
          </Grid.Column>
          <Grid.Column>
            <CategoryDropdown
              onChange={onChange("category")}
              name="category"
              value={item.category ? item.category.id : undefined}
            />
            <br />
            <br />
            <Form.Checkbox
              label="This is One Sized product."
              name="isOnesize"
              onChange={onChange("isOnesize")}
              checked={item.isOnesize}
            />
            <br />
            {pricePackRenderer()}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default ItemInfoFormSection;
