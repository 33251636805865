import api from "./API";
import {
  Product,
  Pack,
  Category,
  Size,
  Image,
  Modify,
  QueryParams,
  FetchResponse,
  PageSize,
} from "./types";
import jsonToFormData from "utils/jsonToFormData";

const ENDPOINT = "v1/products/";

export type productQueryParams = QueryParams & {
  category?: string;
  ordering?: "updated_at" | "-updated_at" | "style_number" | "-style_number";
  pageSize?: PageSize;
};

const getProducts = async (
  query: productQueryParams
): Promise<FetchResponse<Product>> => {
  const response = await api.get(ENDPOINT, { params: query });
  return response.data;
};

const getProductById = async (id: number) => {
  const endpoint = ENDPOINT + id + "/";
  const response = await api.get(endpoint);
  return response.data;
};

const createProduct = async (data: ProductFormValues) => {
  const productData = formatFormData(data);
  const response = await api.post(ENDPOINT, productData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};

const updateProduct = async (id: number, data: ProductFormValues) => {
  const endpoint = ENDPOINT + id + "/";
  const productData = formatFormData(data);
  const response = await api.put(endpoint, productData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};

const deleteProduct = async (id: number) => {
  const endpoint = ENDPOINT + id + "/";
  const response = await api.delete(endpoint);
  return response.data;
};

const removeVariations = async (id: number, data: Array<number>) => {
  const endpoint = ENDPOINT + id + "/remove_variations/";
  const response = await api.post(endpoint, data);
  return response.data;
};

const removeImages = async (id: number, data: Array<number>) => {
  const endpoint = ENDPOINT + id + "/remove_images/";
  const response = await api.post(endpoint, data);
  return response.data;
};

export default {
  getProducts,
  getProductById,
  createProduct,
  updateProduct,
  deleteProduct,
  removeVariations,
  removeImages,
};

export type ImageFormValues = Modify<Image, { id?: number }>;

export interface ProductVariationFormValues {
  id?: number;
  size: Size;
  color: string;
  pack: string;
  packBreakdown: string;
  qtyPerPack?: number;
}

export interface ProductFormValues {
  id?: number;
  styleNumber: string;
  name: string;
  description: string;
  category: Category | null;
  isOnesize: boolean;
  regularPrice: number;
  plusPrice: number;
  onesizePrice: number;
  regularPack: Pack | null;
  plusPack: Pack | null;
  onesizePack: Pack | null;
  variations: ProductVariationFormValues[];
  images: ImageFormValues[];
}

const formatFormData = (formData: ProductFormValues) => {
  const {
    category,
    regularPack,
    plusPack,
    onesizePack,
    regularPrice,
    plusPrice,
    onesizePrice,
    ...rest
  } = formData;
  const data = {
    ...rest,
    category: category ? category.id : null,
    regularPack: regularPack ? regularPack.id : null,
    plusPack: plusPack ? plusPack.id : null,
    onesizePack: onesizePack ? onesizePack.id : null,
    regularPrice: Math.round(Number(regularPrice * 100)),
    plusPrice: Math.round(Number(plusPrice * 100)),
    onesizePrice: Math.round(Number(onesizePrice * 100)),
  };

  return jsonToFormData(data);
};
