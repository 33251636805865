import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import OrderForm from "./OrderForm";
import PageHeader from "components/PageHeader";

interface OrderDetailPageProps {}

const OrderDetailPage: React.FC<OrderDetailPageProps> = ({}) => {
  const { id } = useParams();

  const header = id === "new" ? "Create New Order" : "Edit Order";
  return (
    <>
      <PageHeader header={header} subheader="" icon="unordered list" />
      <OrderForm />
    </>
  );
};

export default OrderDetailPage;
