import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "app/rootReducer";
import { useAppDispatch } from "app/store";
import PageHeader from "components/PageHeader";
import { fetchProductById } from "../productSlice";
import ProductForm from "./ProductForm";

interface ProductDetailPage {
  //
}

const ProductDetailPage: React.FC<ProductDetailPage> = () => {
  const { entities: productsById, loading /* error */ } = useSelector(
    (states: RootState) => states.products
  );
  const dispatch = useAppDispatch();
  const { id } = useParams();

  useEffect(() => {
    if (id !== "new") {
      dispatch(fetchProductById(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProduct = () => {
    if (id === "new") {
      return null;
    }
    return productsById[id];
  };

  const header = id === "new" ? "Create New Product" : "Edit Product";

  return (
    <>
      <PageHeader header={header} subheader="Manage Product" icon="female" />
      <ProductForm product={getProduct()} loading={loading} />
    </>
  );
};

export default ProductDetailPage;
