import { AxiosInstance, AxiosRequestConfig } from "axios";
import jwtDecode from "jwt-decode";
import { CurrentUser } from "./types";

export class TokenStorage {
  private static readonly LOCAL_STORAGE_ACCESS_TOKEN = "access_token";
  private static readonly LOCAL_STORAGE_REFRESH_TOKEN = "refresh_token";
  private static readonly API_ENDPOINT = "token/refresh/";

  public static isAuthenticated(): boolean {
    return this.getAccessToken() !== null;
  }

  public static getAuthentication(): AxiosRequestConfig {
    return {
      headers: {
        Authorization: `Bearer ${this.getAccessToken()}`,
      },
    };
  }

  public static getCurrentUser(): CurrentUser {
    const accessToken = this.getAccessToken();
    if (accessToken) {
      const { user_id, first_name, last_name, role } = jwtDecode(accessToken);
      console.log("getCurrentUser", jwtDecode(accessToken));
      return {
        userId: user_id,
        firstName: first_name,
        lastName: last_name,
        role: role,
      };
    }
    return { userId: null, firstName: null, lastName: null, role: null };
  }

  public static async getNewToken(api: AxiosInstance) {
    const refreshToken = this.getRefreshToken();
    if (!refreshToken) {
      return Promise.reject();
    }
    try {
      const response = await api.post(this.API_ENDPOINT, {
        refresh: this.getRefreshToken(),
      });
      const { access, refresh } = response.data;
      this.storeAccessToken(access);
      this.storeRefreshToken(refresh);
      return Promise.resolve(access);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public static storeAccessToken(accessToken: string): void {
    localStorage.setItem(TokenStorage.LOCAL_STORAGE_ACCESS_TOKEN, accessToken);
  }

  public static storeRefreshToken(refreshToken: string): void {
    localStorage.setItem(
      TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN,
      refreshToken
    );
  }

  public static clear(): void {
    localStorage.removeItem(TokenStorage.LOCAL_STORAGE_ACCESS_TOKEN);
    localStorage.removeItem(TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN);
  }

  private static getRefreshToken(): string | null {
    return localStorage.getItem(TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN);
  }

  private static getAccessToken(): string | null {
    return localStorage.getItem(TokenStorage.LOCAL_STORAGE_ACCESS_TOKEN);
  }
}
