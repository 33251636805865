import { combineReducers } from "@reduxjs/toolkit";

import authReducer from "features/auth/authSlice";
import productReducer from "features/product/productSlice";
import colorReducer from "features/color/colorSlice";
import packReducer from "features/pack/packSlice";
import categoryReducer from "features/category/categorySlice";
import listingReducer from "features/listing/listingSlice";

const appReducer = combineReducers({
  auth: authReducer,
  products: productReducer,
  colors: colorReducer,
  packs: packReducer,
  categories: categoryReducer,
  listings: listingReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === "auth/logout") {
    console.log("rootReducer: reset all states");
    state = undefined;
  }
  return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
