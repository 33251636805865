import React from "react";
import { Size } from "api/types";
import { Form, DropdownProps } from "semantic-ui-react";

interface SizeDropdownProps {
  label: string;
  name: string;
  value: Size | undefined;
  onSelect: Function;
  isOnesize: boolean;
}

const SizeDropdown: React.FC<SizeDropdownProps> = ({
  name,
  value,
  onSelect,
  isOnesize,
}) => {
  const sizeOptions = () => {
    if (isOnesize) {
      return [{ key: "ONE_SIZE", text: "One Size", value: "ONE_SIZE" }];
    }
    return [
      { key: "REGULAR", text: "REGULAR", value: "REGULAR" },
      { key: "PLUS", text: "PLUS", value: "PLUS" },
    ];
  };

  const raiseResult = (
    _: React.SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps
  ) => {
    onSelect(data.value);
  };

  return (
    <Form.Dropdown
      name={name}
      options={sizeOptions()}
      onChange={raiseResult}
      value={value}
      selection
      placeholder="Select Size"
    />
  );
};

export default SizeDropdown;
