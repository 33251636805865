import React, { useState, useEffect, useRef } from "react";
import ReactToPrint from "react-to-print";
import _ from "lodash";
import { useSelector } from "react-redux";
import { Segment, Header, Button } from "semantic-ui-react";
import styled from "styled-components";

import { RootState } from "app/rootReducer";
import { useAppDispatch } from "app/store";
import PageHeader from "components/PageHeader";
import { fetchListings, resetListings } from "features/listing/listingSlice";
import CsvTxtFileDropSection from "components/CsvTxtFileDropSection";
import LinesheetPrint from "./LinesheetPrint";

interface LinesheetPageProps {
  //
}

const ListItem = styled.li`
  display: inline-block;
  width: 100px;
`;

const LinesheetPage: React.FC<LinesheetPageProps> = () => {
  const { entities: listingsById, loading /*error*/ } = useSelector(
    (states: RootState) => states.listings
  );
  const dispatch = useAppDispatch();
  const [queries, setQueries] = useState<string[]>([]);
  const [queriesNotFound, setQueriesNotFound] = useState<string[]>([]);
  const [listings, setListings] = useState<string[]>([]);
  const [searched, setSearched] = useState<boolean>(false);

  const printRef = useRef(null);

  useEffect(() => {
    const listings = Object.values(listingsById).map(
      (listing) => listing.styleNumber
    );
    if (queries.length > 0) {
      setQueriesNotFound(_.xor(listings, queries));
    }
    setListings(listings);
  }, [listingsById, queries]);

  const cleanUp = () => {
    dispatch(resetListings());
    setQueries([]);
    setListings([]);
    setQueriesNotFound([]);
    setSearched(false);
  };

  const handleFileRead = (inputQueries: string[]) => {
    const newQuerySet = _.sortedUniq(_.concat(queries, inputQueries));
    setQueries(newQuerySet);
  };

  const handleSearch = () => {
    dispatch(
      fetchListings({
        search: queries.join(","),
        lookup: "iexact",
        isMain: true,
        page: 1,
        pageSize: 100,
      })
    );
    setSearched(true);
  };

  return (
    <>
      <PageHeader
        header="Linesheet"
        subheader="Print Linesheet"
        icon="newspaper outline"
      />
      <Segment.Group>
        <Segment>
          <CsvTxtFileDropSection onFileRead={handleFileRead} />
        </Segment>
        <Segment style={{ paddingBottom: "50px" }}>
          <Header
            size="medium"
            content="Listings to search:"
            subheader={`${queries.length} items.`}
          />
          {queries.length > 0 && (
            <>
              <ul>
                {queries.map((q, i) => (
                  <ListItem key={i}>{q}</ListItem>
                ))}
              </ul>
              <Button
                content="Clear"
                loading={loading}
                floated="right"
                onClick={cleanUp}
                secondary
              />
              <Button
                content="Search"
                loading={loading}
                floated="right"
                onClick={() => handleSearch()}
                primary
              />
            </>
          )}
          {searched && queriesNotFound.length > 0 && (
            <>
              <Header
                size="medium"
                content="Following listings were not found:"
                subheader={`${queriesNotFound.length} items.`}
              />
              <ul>
                {queriesNotFound.map((q, i) => (
                  <ListItem key={i}>{q}</ListItem>
                ))}
              </ul>
            </>
          )}
          {listings.length > 0 && (
            <>
              <Header size="medium" content="Print Instruction" />
              <ul>
                <li>
                  <strong>Layout (레이아웃)</strong>: Landscape (가로)
                </li>
                <li>
                  <strong>Margin (여백)</strong>: None (없음)
                </li>
                <li>
                  <strong>Scale (배율)</strong>: default (기본값) or custom:100
                  (맞춤설정:100)
                </li>
              </ul>
              <ReactToPrint
                trigger={() => (
                  <Button content="Print" floated="right" primary />
                )}
                content={() => printRef.current}
                onAfterPrint={() => cleanUp()}
              />
            </>
          )}
        </Segment>
        <Segment style={{ display: "none" }}>
          <LinesheetPrint
            ref={printRef}
            listings={Object.values(listingsById)}
          />
        </Segment>
      </Segment.Group>
    </>
  );
};

export default LinesheetPage;
