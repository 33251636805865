import React from "react";
import { useSelector } from "react-redux";
import { Pack, Size } from "api/types";
import { RootState } from "app/rootReducer";
import { DropdownProps, Form } from "semantic-ui-react";

interface PackDropdownProps {
  label: string;
  name: string;
  size: Size;
  value: number | undefined;
  onChange: (
    e: React.SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps
  ) => void;
}

const PackDropdown: React.FC<PackDropdownProps> = ({
  label,
  name,
  value,
  size,
  onChange,
}) => {
  const { entities: packsById } = useSelector(
    (states: RootState) => states.packs
  );

  const packOptions = () => {
    const packs = Object.values(packsById).filter((p: Pack) => p.size === size);
    const options = packs.map((p: Pack) => {
      return {
        key: p.id,
        text: `${p.name} (${p.breakdown})`,
        value: p.id,
      };
    });
    options.unshift({ key: 0, text: "No pack", value: 0 });
    return options;
  };

  return (
    <Form.Dropdown
      label={label}
      name={name}
      options={packOptions()}
      onChange={onChange}
      value={value ? value : 0}
      selection
      placeholder="Select Pack"
    />
  );
};

export default PackDropdown;
